import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy verklaring</h1>
            <p>Bij Zonnemacht hechten wij veel waarde aan de privacy van onze gebruikers. In deze privacy verklaring leggen wij uit welke persoonlijke gegevens we verzamelen wanneer u onze website bezoekt en hoe we deze gegevens gebruiken.</p>
            <h2>1. Gegevens verzamelen</h2>
            <p>Bij het bezoeken van Zonnemacht kunnen bepaalde gegevens automatisch worden verzameld, zoals uw IP-adres, type browser, datum en tijd van uw bezoek, bezochte pagina's en andere relevante informatie. Om deze gegevens te verzamelen gebruiken wij Google Analytics.</p>
            <h2>2. Gegevens gebruiken </h2>
            <p>De gegevens die we via Google Analytics verzamelen, worden gebruikt om het websiteverkeer te analyseren, de prestaties van onze website te verbeteren en inzicht te krijgen in het gedrag van onze gebruikers. Deze gegevens worden strikt vertrouwelijk behandeld en worden niet gedeeld met derden, tenzij dit wettelijk vereist is.</p>
            <h2>3. Cookies toelichting</h2>
            <p>Wij maken gebruik van cookies om uw voorkeuren te onthouden en een optimale gebruikerservaring te bieden. Cookies zijn kleine tekstbestanden die op uw apparaat worden geplaatst wanneer u onze website bezoekt. Door cookies te gebruiken, kunnen we bijvoorbeeld uw taalvoorkeur onthouden en ervoor zorgen dat u niet telkens dezelfde informatie hoeft in te voeren wanneer u onze website bezoekt. U heeft de mogelijkheid om cookies te weigeren of om ze te verwijderen. Raadpleeg de helpfunctie van uw browser om te achterhalen hoe u dit kunt doen. Houd er echter rekening mee dat het uitschakelen van cookies van invloed kan zijn op de functionaliteit van onze website.</p>
            <h2>4. Beveiliging</h2>
            <p>We nemen passende technische en organisatorische maatregelen om uw gegevens te beschermen tegen ongeoorloofde toegang, onbedoeld verlies, wijziging of openbaarmaking. We gebruiken beveiligingsmaatregelen zoals firewalls, versleuteling en strikte toegangscontroles om ervoor te zorgen dat uw gegevens veilig zijn.</p>
            <h2>5. Uw rechten</h2>
            <p>U heeft het recht om toegang te krijgen tot uw persoonlijke gegevens die we verzamelen en om eventuele onjuiste gegevens te corrigeren. Als u vragen heeft over ons privacybeleid of als u uw gegevens wilt laten verwijderen, neem dan contact met ons op door te mailen naar contact@zonnemacht.nl.</p>
            <h2>6. Wijzigingen in het Privacybeleid</h2>
            <p>Dit privacybeleid kan van tijd tot tijd worden gewijzigd om te voldoen aan wettelijke vereisten of om eventuele wijzigingen in onze bedrijfsactiviteiten weer te geven. We raden u aan om regelmatig ons privacybeleid te controleren voor eventuele updates.</p>
            <p>Door gebruik te maken van Zonnemacht.nl en de diensten die Zonnemacht heeft te bieden stemt u in met dit privacybeleid en de verzameling en verwerking van uw persoonlijke gegevens zoals hierin beschreven.

            </p>
        </div>
    );
}

export default PrivacyPolicy;

//TODO Re-format text