import React from 'react';
import './InfoSolarEnergyBilling.css';
import {Image, Header, Share, Paragraph, ListWithHeader} from "./Helper/Article";
import SmallImage from "../../../../../assets/background1.png"

function InfoSolarEnergyBilling() {

    // Used in line 45
    const myList = [
        { header: "2023-2024:", content: "Je kunt nog steeds 100% salderen, wat betekent dat je de volledige opbrengst van je zonnepanelen kunt verrekenen met je verbruik en Terugleververgoeding kunt ontvangen voor de ongebruikte stroom." },
        { header: "2025:", content: "Salderen wordt beperkt tot 64%. Dit betekent dat je slechts 64% van je opgewekte energie kunt verrekenen met je verbruik, terwijl de rest wordt beschouwd als 'niet-salderen'. Je kunt een deel van deze niet-gesaldeerde stroom direct gebruiken en een deel ervan wordt teruggeleverd aan het net." },
        { header: "2026:", content: "In dit jaar blijft de 64% saldering van kracht. Een aanzienlijk deel van de niet-gesaldeerde stroom moet echter worden gekocht van je energieleverancier, wat je energiekosten kan verhogen." },
        { header: "2027-2030::", content: "Het percentage salderen daalt geleidelijk tot 28% in 2030. Dit betekent dat je steeds meer van je opgewekte stroom direct moet consumeren om kosten te besparen." },
        { header: "2031 en daarna::", content: "Salderen wordt afgeschaft, wat betekent dat je geen gebruik meer kunt maken van de saldeerregeling. In plaats daarvan ontvang je een Terugleververgoeding voor de stroom die je teruglevert aan het net en betaal je het normale stroomtarief voor de stroom die je aanvult uit het net." },
    ];

    return (
        <div className="info-solar-power-billing-container">

            <Header Header={"Salderen, Terugleververgoeding, Terugleverkosten: Alles Wat Je Moet Weten"} size={'bigger'}/>
            <Share/>

            <Image ImageUrl={SmallImage}/>

            <Paragraph>Salderen is een begrip in de wereld van zonne-energie dat van groot belang is voor huishoudens en bedrijven die zonnepanelen hebben geïnstalleerd. Het kan aanzienlijke besparingen opleveren en invloed hebben op je energierekening. In dit artikel leggen we salderen en de afschaffing ervan uit, en bekijken we de gevolgen voor de opbrengsten van zonnepanelen. Bovendien bespreken we enkele voorbeelden en rekenvoorbeelden om de concepten te verduidelijken.</Paragraph>

            <Header Header={"Salderen Uitgelegd"} size={'big'}/>
            <Paragraph>Salderen is een regeling waarbij je de energie die je opwekt met zonnepanelen kunt verrekenen met de energie die je verbruikt. Concreet betekent dit dat als je bijvoorbeeld 0,40 cent per kilowattuur (kWh) betaalt voor de stroom die je van het elektriciteitsnet afneemt, je ook 0,40 cent per kWh ontvangt voor de stroom die je teruglevert aan het net.</Paragraph>
            <Paragraph>Als je meer elektriciteit teruglevert dan je verbruikt, kom je in aanmerking voor een Terugleververgoeding per kWh. Dit is een vergoeding die je ontvangt voor de overschot aan opgewekte elektriciteit die je teruglevert aan het elektriciteitsnet.</Paragraph>

            <Header Header={"Saldo Berekenen"} size={'medium'}/>
            <Paragraph>Om je saldo te berekenen, moet je de gebruikte stroom (verbruik), de opgewekte stroom en het saldo bijhouden. Het saldo is het verschil tussen je stroomverbruik en de stroom die je opwekt.</Paragraph>

            <Header Header={"Voorbeeld"} size={'medium'}/>
            <Paragraph>Laten we een voorbeeld bekijken. Stel dat je jaarlijks 3000 kWh aan elektriciteit verbruikt en dat je energietarief €0,40 per kWh bedraagt. Je zou dan €1200 per jaar betalen voor elektriciteit.</Paragraph>

            <Paragraph>Als je zonnepanelen hebt geïnstalleerd en jaarlijks 5000 kWh aan stroom opwekt, kun je 3000 kWh van je eigen opgewekte stroom inzetten om je energieverbruik te dekken. Dit staat bekend als 1:1 salderen en zou resulteren in een besparing van €1200.</Paragraph>
            <Paragraph>Echter, als je nog steeds 2000 kWh aan ongebruikte stroom teruglevert aan het elektriciteitsnet, kun je aanspraak maken op een Terugleververgoeding van bijvoorbeeld €0,05 per kWh, wat neerkomt op een extra besparing van €100.</Paragraph>

            <Header Header={"Afschaffing van Salderen"} size={'big'}/>
            <Share/>
            <Paragraph>Het landschap van salderen is aan verandering onderhevig, en de regeling zal naar verwachting in de komende jaren worden afgebouwd. Hier zijn de belangrijkste punten om te begrijpen:</Paragraph>

            <ListWithHeader listArrayWithHeader={myList}/>

            <Paragraph>Deze veranderingen kunnen aanzienlijke gevolgen hebben voor de financiële voordelen van zonnepanelen en benadrukken het belang van een efficiënt energiegebruik en het maximaliseren van je eigen gebruik van opgewekte elektriciteit.</Paragraph>

            <Header Header={"Opbrengsten Zonder Salderen"} size={'medium'}/>
            <Paragraph>Naarmate de saldeerregeling wordt afgebouwd, is het belangrijk om te begrijpen hoe dit van invloed kan zijn op je financiën. In een situatie zonder salderen moet je zelfconsumptie van opgewekte stroom prioriteit geven.</Paragraph>

            <Header Header={"Terugleveringskosten"} size={'medium'}/>
            <Paragraph>Wanneer je elektriciteit teruglevert aan het net, kunnen er terugleveringskosten van toepassing zijn. Deze kosten variëren en zijn afhankelijk van je elektriciteitsleverancier en de hoeveelheid teruggeleverde stroom.</Paragraph>

            <Header Header={"Voorbeeld van Terugleveringskosten"} size={'medium'}/>
            <Paragraph>Stel dat je jaarlijks 2200 kWh aan elektriciteit teruglevert aan het net. Je elektriciteitsleverancier kan een maandelijkse vaste vergoeding in rekening brengen, bijvoorbeeld €21 per maand, voor de teruglevering.</Paragraph>

            <Paragraph>Alle opgewekte stroom die niet direct wordt gebruikt en dus eerst via het elektriciteitsnet wordt teruggeleverd, wordt opgeteld, wat resulteert in een totale hoeveelheid teruggeleverde stroom.</Paragraph>

            <Header Header={"Rekenvoorbeeld Terugleveringskosten"} size={'medium'}/>
            <Paragraph>Stel dat je in totaal 2500 kWh aan elektriciteit hebt teruggeleverd. Je hebt bijvoorbeeld 2000 kWh gesaldeerd en 500 kWh opgewekte stroom die niet direct is gebruikt. Dit wordt opgeteld tot 2500 kWh.</Paragraph>

            <Paragraph>Je energieleverancier hanteert schalen voor terugleveringskosten. Als je tussen 2000 en 3000 kWh teruglevert, zou je bijvoorbeeld in schaal 3 vallen. Deze schaal kan bijvoorbeeld een bedrag van €0,69 per dag of €252 per jaar omvatten.</Paragraph>
            <Paragraph>Om je werkelijke opbrengsten te berekenen, trek je de terugleveringskosten af van je totale opbrengsten, wat resulteert in je netto financiële voordeel.</Paragraph>
            <Paragraph>Houd er rekening mee dat terugleveringskosten meestal niet van toepassing zijn op dynamische contracten, maar dit kan nadelig zijn als je veel elektriciteit teruglevert aan het net.</Paragraph>
            <Paragraph>In dit artikel hebben we salderen, de afschaffing van salderen en de financiële gevolgen voor zonnepanelen besproken. Het is van groot belang om op de hoogte te blijven van de veranderingen in de saldeerregeling en om te overwegen hoe je optimaal gebruik kunt maken van je zonne-energie om kosten te besparen. We raden aan om advies in te winnen bij een deskundige op het gebied van zonne-energie om te bepalen wat het beste is voor jouw situatie.</Paragraph>

            <Share/>
        </div>
    );
}
export default InfoSolarEnergyBilling;




{/*<h1>Salderen, Terugleververgoeding, Terugleverkosten: Alles Wat Je Moet Weten</h1>*/}

{/*<ShareButton />*/}

{/*<p>Salderen is een begrip in de wereld van zonne-energie dat van groot belang is voor huishoudens en bedrijven die zonnepanelen hebben geïnstalleerd. Het kan aanzienlijke besparingen opleveren en invloed hebben op je energierekening. In dit artikel leggen we salderen en de afschaffing ervan uit, en bekijken we de gevolgen voor de opbrengsten van zonnepanelen. Bovendien bespreken we enkele voorbeelden en rekenvoorbeelden om de concepten te verduidelijken.</p>*/}

{/*<h2>Salderen Uitgelegd</h2>*/}
{/*<p>Salderen is een regeling waarbij je de energie die je opwekt met zonnepanelen kunt verrekenen met de energie die je verbruikt. Concreet betekent dit dat als je bijvoorbeeld 0,40 cent per kilowattuur (kWh) betaalt voor de stroom die je van het elektriciteitsnet afneemt, je ook 0,40 cent per kWh ontvangt voor de stroom die je teruglevert aan het net.</p>*/}

{/*<p>Als je meer elektriciteit teruglevert dan je verbruikt, kom je in aanmerking voor een Terugleververgoeding per kWh. Dit is een vergoeding die je ontvangt voor de overschot aan opgewekte elektriciteit die je teruglevert aan het elektriciteitsnet.</p>*/}

{/*<h3>Saldo Berekenen</h3>*/}
{/*<p>Om je saldo te berekenen, moet je de gebruikte stroom (verbruik), de opgewekte stroom en het saldo bijhouden. Het saldo is het verschil tussen je stroomverbruik en de stroom die je opwekt.</p>*/}

{/*<h3>Voorbeeld</h3>*/}
{/*<p>Laten we een voorbeeld bekijken. Stel dat je jaarlijks 3000 kWh aan elektriciteit verbruikt en dat je energietarief €0,40 per kWh bedraagt. Je zou dan €1200 per jaar betalen voor elektriciteit.</p>*/}

{/*<p>Als je zonnepanelen hebt geïnstalleerd en jaarlijks 5000 kWh aan stroom opwekt, kun je 3000 kWh van je eigen opgewekte stroom inzetten om je energieverbruik te dekken. Dit staat bekend als 1:1 salderen en zou resulteren in een besparing van €1200.</p>*/}

{/*<p>Echter, als je nog steeds 2000 kWh aan ongebruikte stroom teruglevert aan het elektriciteitsnet, kun je aanspraak maken op een Terugleververgoeding van bijvoorbeeld €0,05 per kWh, wat neerkomt op een extra besparing van €100.</p>*/}
{/*<ShareButton />*/}
{/*<h2>Afschaffing van Salderen</h2>*/}
{/*<p>Het landschap van salderen is aan verandering onderhevig, en de regeling zal naar verwachting in de komende jaren worden afgebouwd. Hier zijn de belangrijkste punten om te begrijpen:</p>*/}

{/*<ul>*/}
{/*    <li><strong>2023-2024:</strong> Je kunt nog steeds 100% salderen, wat betekent dat je de volledige opbrengst van je zonnepanelen kunt verrekenen met je verbruik en Terugleververgoeding kunt ontvangen voor de ongebruikte stroom.</li>*/}
{/*    <li><strong>2025:</strong> Salderen wordt beperkt tot 64%. Dit betekent dat je slechts 64% van je opgewekte energie kunt verrekenen met je verbruik, terwijl de rest wordt beschouwd als 'niet-salderen'. Je kunt een deel van deze niet-gesaldeerde stroom direct gebruiken en een deel ervan wordt teruggeleverd aan het net.</li>*/}
{/*    <li><strong>2026:</strong> In dit jaar blijft de 64% saldering van kracht. Een aanzienlijk deel van de niet-gesaldeerde stroom moet echter worden gekocht van je energieleverancier, wat je energiekosten kan verhogen.</li>*/}
{/*    <li><strong>2027-2030:</strong> Het percentage salderen daalt geleidelijk tot 28% in 2030. Dit betekent dat je steeds meer van je opgewekte stroom direct moet consumeren om kosten te besparen.</li>*/}
{/*    <li><strong>2031 en daarna:</strong> Salderen wordt afgeschaft, wat betekent dat je geen gebruik meer kunt maken van de saldeerregeling. In plaats daarvan ontvang je een Terugleververgoeding voor de stroom die je teruglevert aan het net en betaal je het normale stroomtarief voor de stroom die je aanvult uit het net.</li>*/}
{/*</ul>*/}

{/*<p>Deze veranderingen kunnen aanzienlijke gevolgen hebben voor de financiële voordelen van zonnepanelen en benadrukken het belang van een efficiënt energiegebruik en het maximaliseren van je eigen gebruik van opgewekte elektriciteit.</p>*/}
{/*<ShareButton />*/}


{/*<h2>Opbrengsten Zonder Salderen</h2>*/}
{/*<p>Naarmate de saldeerregeling wordt afgebouwd, is het belangrijk om te begrijpen hoe dit van invloed kan zijn op je financiën. In een situatie zonder salderen moet je zelfconsumptie van opgewekte stroom prioriteit geven.</p>*/}

{/*<h2>Terugleveringskosten</h2>*/}
{/*<p>Wanneer je elektriciteit teruglevert aan het net, kunnen er terugleveringskosten van toepassing zijn. Deze kosten variëren en zijn afhankelijk van je elektriciteitsleverancier en de hoeveelheid teruggeleverde stroom.</p>*/}

{/*<h3>Voorbeeld van Terugleveringskosten</h3>*/}
{/*<p>Stel dat je jaarlijks 2200 kWh aan elektriciteit teruglevert aan het net. Je elektriciteitsleverancier kan een maandelijkse vaste vergoeding in rekening brengen, bijvoorbeeld €21 per maand, voor de teruglevering.</p>*/}

{/*<p>Alle opgewekte stroom die niet direct wordt gebruikt en dus eerst via het elektriciteitsnet wordt teruggeleverd, wordt opgeteld, wat resulteert in een totale hoeveelheid teruggeleverde stroom.</p>*/}

{/*<h3>Rekenvoorbeeld Terugleveringskosten</h3>*/}
{/*<p>Stel dat je in totaal 2500 kWh aan elektriciteit hebt teruggeleverd. Je hebt bijvoorbeeld 2000 kWh gesaldeerd en 500 kWh opgewekte stroom die niet direct is gebruikt. Dit wordt opgeteld tot 2500 kWh.</p>*/}

{/*<p>Je energieleverancier hanteert schalen voor terugleveringskosten. Als je tussen 2000 en 3000 kWh teruglevert, zou je bijvoorbeeld in schaal 3 vallen. Deze schaal kan bijvoorbeeld een bedrag van €0,69 per dag of €252 per jaar omvatten.</p>*/}

{/*<p>Om je werkelijke opbrengsten te berekenen, trek je de terugleveringskosten af van je totale opbrengsten, wat resulteert in je netto financiële voordeel.</p>*/}

{/*<p>Houd er rekening mee dat terugleveringskosten meestal niet van toepassing zijn op dynamische contracten, maar dit kan nadelig zijn als je veel elektriciteit teruglevert aan het net.</p>*/}

{/*<p>In dit artikel hebben we salderen, de afschaffing van salderen en de financiële gevolgen voor zonnepanelen besproken. Het is van groot belang om op de hoogte te blijven van de veranderingen in de saldeerregeling en om te overwegen hoe je optimaal gebruik kunt maken van je zonne-energie om kosten te besparen. We raden aan om advies in te winnen bij een deskundige op het gebied van zonne-energie om te bepalen wat het beste is voor jouw situatie.</p>*/}
{/*<ShareButton />*/}
