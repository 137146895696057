import React, {useEffect, useState} from 'react';
import axios from "axios";
import './ProductQuickView.css';
import './Solarpanel.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PrimaryCta from "../../../../../cta/PrimaryCta";


function Solarpanel({setSolarPanelUuid}) {
    const [solarPanels, setSolarPanels] = useState([]);
    const [currentPanelIndex, setCurrentPanelIndex] = useState(0);
    const [selectedPanelIndex, setSelectedPanelIndex] = useState(null);

    useEffect(() => {
        async function fetchSolarPanels() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_HOST}/solarpanels/allavailable`); //Added Solarpanel/allavailable to be the public end point

                setSolarPanels(response.data);
            } catch (error) {
            }
        }

        fetchSolarPanels();
    }, []);

    const productQuickView = (panel) => {
        if (!panel) {
            return <p>Loading...</p>;
        }

        return (
            <div className="product-quick-view">

                <div className="product-quick-view-align-column">
                    <div className="product-image">
                        {panel.imageUrl && (<img src={panel.imageUrl} alt="Assigned Solar Panel"/>)}
                    </div>

                    <div className="product-title">
                        <div className="product-features">
                            <p>Kracht: {panel.power}Wp.</p>
                            <p className="product-guarantee-guarantee"> Productgarantie: {panel.guaranteeInYears} jaar</p>
                            <p className="product-price">Kost: €{panel.price},- </p>

                            <span>Maat: {panel.length}
                                <span className="sp-display-size-x-config">x </span>{panel.width} <span
                                    className="sp-display-size-x-config">x </span>{panel.height}<span
                                    className="sp-display-size-mm-config">mm</span>.</span> {/*fontsize of the 'x' inside sp-display-sizes must be 12px*/}
                        </div>
                        <div className="product-features">
                            <PrimaryCta
                                primaryText="Kies paneel en bekijk rapport"
                                primaryLink="/main-result"
                            />
                        </div>
                        <div className="product-features">
                            <p className="product-unique-point"><strong>Uniek aan dit segment</strong></p>
                            <p>{panel.uniqueSellingPoint}</p>
                            <p className="product-best-pick"><strong>Voorbeeld keuze-scenario</strong></p>
                            <p>{panel.bestPickScenario}</p>
                        </div>


                    </div>
                </div>
            </div>
        );
    };


    const handlePanelSelection = (index) => {
        setSelectedPanelIndex(index);

        // Get the UUID of the selected solar panel
        const selectedPanelUuid = solarPanels[index]?.uuid;

        // Set the selected panel's UUID in the parent component
        setSolarPanelUuid(selectedPanelUuid);

        // You can also perform any additional actions here, such as sending data to the backend
    };


    return (
        <div className="solarpanel-container">
            <div className="list-container">
                <div className="panel-list">
                    <div className="solar-panel-grid">
                        {solarPanels.map((panel, index) => (
                            <div key={index}
                                 className={`solar-panel-item ${selectedPanelIndex === index ? 'selected-item' : ''}`}
                                 onClick={() => handlePanelSelection(index)}>

                                <div className="panel-image">
                                    {panel.imageUrl && <img src={panel.imageUrl} alt="Solar Panel"/>}
                                </div>
                                <div>
                                    <h3 className="panel-brand">{panel.brand}</h3>
                                    <h4 className="panel-name">{panel.name}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="panel-navigation">
                    {productQuickView(
                        selectedPanelIndex !== null
                            ? solarPanels[selectedPanelIndex]
                            : solarPanels[currentPanelIndex]
                    )}
                </div>
            </div>
        </div>
    );
}

export default Solarpanel;
