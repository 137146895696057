import React from 'react';
import IntroductionPriceIndication from "../../homepage/IntroductionPriceIndication";
import IntroductionAppointment from "../../homepage/IntroductionAppointment";
import gsap from "gsap";
import {Share} from "./Helper/Article";

function CalculatorAndAppointment() {
    // Disabled for now, don't remove (we can use it later with other components)

    function Animate(element, xValue){
        const ctx = gsap.context(() => {

            // Initialize GSAP and ScrollTrigger
            // gsap.registerPlugin(ScrollTrigger);
            //
            // if (element.current) {
            //     gsap.from(element.current, {
            //         scrollTrigger: {
            //             trigger: element.current,
            //             start: '5% 90%',
            //             toggleActions: 'restart none none none',
            //         },
            //         x: xValue,
            //         opacity: 0,
            //         duration: 1,
            //         ease: 'back'
            //     });
            // }

        });
        return () => { ctx.revert(); } // <- cleanup!
    }

    return (
        <div className="calculator-and-appointment">
            <Share/>
            <div><IntroductionPriceIndication Animate={Animate}/></div>
            <div><IntroductionAppointment Animate={Animate}/></div>
        </div>
    );
}

export default CalculatorAndAppointment;
