import React from 'react';
import './ShareButton.css';
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton, LinkedinShareButton,
    WhatsappIcon, TwitterIcon, LinkedinIcon
} from "react-share";

//TODO add read count to the article

const ShareButton = () => {

    let currentURL = window.location.href;
    let quote = "Checkout this amazing article";

    const buttonStyle = {
        margin: '5px 10px 5px 10px',
        borderRadius: '20px',
    };

    return (
        <div className="share-buttons-container">
            <div className="share-button">
                <FacebookShareButton url={currentURL} >
                    <FacebookIcon size={40} style={buttonStyle}/>
                </FacebookShareButton>
            </div>

            <div>
                <LinkedinShareButton url={currentURL}>
                    <LinkedinIcon size={40} style={buttonStyle}/>
                </LinkedinShareButton>
            </div>

            <div>
                <TwitterShareButton url={currentURL}>
                    <TwitterIcon size={40} style={buttonStyle}/>
                </TwitterShareButton>
            </div>



            <div>
                <WhatsappShareButton url={currentURL}>
                    <WhatsappIcon size={40} style={buttonStyle}/>
                </WhatsappShareButton>
            </div>
        </div>
    );
};

export default ShareButton;


//Unused code

{/*<div>*/}
{/*    <TelegramShareButton url={currentURL}>*/}
{/*        <TelegramIcon size={40} style={buttonStyle}/>*/}
{/*    </TelegramShareButton>*/}
{/*</div>*/}


{/*<div>*/}
{/*    <RedditShareButton url={currentURL}>*/}
{/*        <RedditIcon size={40} style={buttonStyle}/>*/}
{/*    </RedditShareButton>*/}
{/*</div>*/}