import React, { useState, useEffect } from 'react';
import './PriceIndicationAdmin.css';
import BackToAdminPanel from "../buttons/BackToAdminPanel";
import axios from "axios"; // Import the CSS file

const PriceIndicationAdmin = () => {
    const [priceIndications, setPriceIndications] = useState([]);
    const [newPriceIndication, setNewPriceIndication] = useState({
        name: '',
        flatRoofAssemblyMaterialPrice: '',
        slantedRoofAssemblyMaterialPrice: '',
        anchoring: '',
        heightTransport: '',
        deliveryCost: '',
        inversion: '',
        electrics: '',
        installationTeam: '',
        electricsIncrement: '',
        installationTeamIncrement: ''
    });
    const [selectedPriceIndicationId, setSelectedPriceIndicationId] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(()=>{
        fetchPriceIndications();
    },[])


    const fetchPriceIndications = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/priceindication/all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setPriceIndications(data);
        } catch (error) {
            // Optionally, you can show an error message to the user or handle the error in a different way.
        }
    };

    const handleAdd = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST}/priceindication/create`, newPriceIndication,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                await fetchPriceIndications();
            } else {
            }
        } catch (error) {
        }
    };


    const handleUpdate = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_HOST}/priceindication/update`, newPriceIndication,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                await fetchPriceIndications();
            } else {
            }
        } catch (error) {
        }
    };







    const isAddButtonDisabled = priceIndications.length > 0;

    return (
        <div className="admin-price-indication-container">
            <div className="admin-price-indication-section">
                <div className="admin-price-indication-values">
                    <h2 className="admin-price-indication-header">Current values</h2>
                    <div className="admin-price-indication-list">
                        {priceIndications.map(priceIndication => (
                            <div key={priceIndication.name}>
                                <p className="admin-price-indication-label">Naam:</p>
                                <p className="admin-price-indication-value">{priceIndication.name}</p>

                                <p className="admin-price-indication-label">Platdak montage materiaal:</p>
                                <p className="admin-price-indication-value">{priceIndication.flatRoofAssemblyMaterialPrice}</p>

                                <p className="admin-price-indication-label">Schuindak montage materiaal:</p>
                                <p className="admin-price-indication-value">{priceIndication.slantedRoofAssemblyMaterialPrice}</p>

                                <p className="admin-price-indication-label">Verankering:</p>
                                <p className="admin-price-indication-value">{priceIndication.anchoring}</p>

                                <p className="admin-price-indication-label">Hoogte transport:</p>
                                <p className="admin-price-indication-value">{priceIndication.heightTransport}</p>

                                <p className="admin-price-indication-label">Levering:</p>
                                <p className="admin-price-indication-value">{priceIndication.deliveryCost}</p>

                                <p className="admin-price-indication-label">Omvorming:</p>
                                <p className="admin-price-indication-value">{priceIndication.inversion}</p>

                                <p className="admin-price-indication-label">Elektra:</p>
                                <p className="admin-price-indication-value">{priceIndication.electrics}</p>

                                <p className="admin-price-indication-label">Installatie Team:</p>
                                <p className="admin-price-indication-value">{priceIndication.installationTeam}</p>

                                <p className="admin-price-indication-label">Elektra verhoging:</p>
                                <p className="admin-price-indication-value">{priceIndication.electricsIncrement}</p>

                                <p className="admin-price-indication-label">Installatie Team verhoging:</p>
                                <p className="admin-price-indication-value">{priceIndication.installationTeamIncrement}</p>
                            </div>
                        ))}
                    </div>                </div>
                <BackToAdminPanel/>
            </div>
            <div className="admin-price-indication-section">
                <div className="admin-price-indication-update">
                    <h2 className="admin-price-indication-header">Vul de nieuwe waardes in</h2>
                    <div>
                        <label className="admin-price-indication-label">Naam: </label>
                        <input className="admin-price-indication-input" type="text" value={newPriceIndication.name} onChange={e => setNewPriceIndication({ ...newPriceIndication, name: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Platdak montage materiaal: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.flatRoofAssemblyMaterialPrice} onChange={e => setNewPriceIndication({ ...newPriceIndication, flatRoofAssemblyMaterialPrice: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Schuindak montage materiaal: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.slantedRoofAssemblyMaterialPrice} onChange={e => setNewPriceIndication({ ...newPriceIndication, slantedRoofAssemblyMaterialPrice: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Verankering: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.anchoring} onChange={e => setNewPriceIndication({ ...newPriceIndication, anchoring: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Hoogte transport: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.heightTransport} onChange={e => setNewPriceIndication({ ...newPriceIndication, heightTransport: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Levering: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.deliveryCost} onChange={e => setNewPriceIndication({ ...newPriceIndication, deliveryCost: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Omvorming: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.inversion} onChange={e => setNewPriceIndication({ ...newPriceIndication, inversion: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Elektra: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.electrics} onChange={e => setNewPriceIndication({ ...newPriceIndication, electrics: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Installatie Team: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.installationTeam} onChange={e => setNewPriceIndication({ ...newPriceIndication, installationTeam: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Elektra verhoging: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.electricsIncrement} onChange={e => setNewPriceIndication({ ...newPriceIndication, electricsIncrement: e.target.value })} />
                    </div>
                    <div>
                        <label className="admin-price-indication-label">Installatie team verhoging: </label>
                        <input className="admin-price-indication-input" type="number" value={newPriceIndication.installationTeamIncrement} onChange={e => setNewPriceIndication({ ...newPriceIndication, installationTeamIncrement: e.target.value })} />
                    </div>
                    {/*disabled={isAddButtonDisabled}*/}
                    <button className="admin-price-indication-button" onClick={handleAdd} >Add</button>

                    <button className="admin-price-indication-button" onClick={handleUpdate}>Update</button>

                </div>

            </div>

        </div>

    );
}

export default PriceIndicationAdmin;

