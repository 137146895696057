import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DeleteBp.css';
import {useNavigate} from "react-router-dom";


const DeleteBp = () => {
    const [selectedProvince, setSelectedProvince] = useState('');
    const [businessProfiles, setBusinessProfiles] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [filterDisabled, setFilterDisabled] = useState(false);


    const fetchBusinessProfiles = async (province, enabled, disabled) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/businessprofile/allavailable`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    params: {
                        province: province || '', // Include province in the request if selected
                        enabled,
                        disabled,
                    },
                }
            );

            setBusinessProfiles(response.data);
        } catch (error) {
            console.error('Error fetching business profiles:', error);
            // Handle error, e.g., display an error message to the user
        }
    };


    useEffect(() => {
        fetchBusinessProfiles(selectedProvince, filterEnabled, filterDisabled);
    }, [selectedProvince, filterEnabled, filterDisabled]);

    const handleDeleteBp = async (uuid) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_HOST}/businessprofile/${uuid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            await fetchBusinessProfiles();
        } catch (error) {
            alert("Error: " + error);
        }
    };


    const provinces = [
        'Nederland',
        'Groningen',
        'Friesland',
        'Drenthe',
        'Overijssel',
        'Flevoland',
        'Gelderland',
        'Utrecht',
        'Noord-Holland',
        'Zuid-Holland',
        'Zeeland',
        'Noord-Brabant',
        'Limburg',
    ];



    return (
        <div className="validate-bp-container">
            <h1><strong>Verwijder</strong> aanbieders in: {selectedProvince || 'Nederland'}</h1>

            <label htmlFor="province">Selecteer provincie:</label>
            <select
                id="province"
                onChange={(e) => setSelectedProvince(e.target.value)}
                value={selectedProvince}
            >
                <option value="">Kies provincie</option>
                {provinces.map((province) => (
                    <option key={province} value={province}>
                        {province}
                    </option>
                ))}
            </select>
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={filterEnabled}
                        onChange={() => setFilterEnabled(!filterEnabled)}
                    />
                    Enabled
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filterDisabled}
                        onChange={() => setFilterDisabled(!filterDisabled)}
                    />
                    Disabled
                </label>
            </div>
            <br/>
            <ul>
                {businessProfiles
                    .filter(profile => (filterEnabled && profile.enabled) || (filterDisabled && !profile.enabled) || (!filterEnabled && !filterDisabled))
                    .map((profile, index) => (
                        <div key={index}>
                            <button
                                style={{ margin: '0 5px', backgroundColor: 'red', color: 'white' }}
                                onClick={() => handleDeleteBp(profile.uuid)}
                            >
                                Delete Bp
                            </button>
                            <p><strong>UUID:</strong> {profile.uuid}</p>
                            <p><strong>Created At:</strong> {profile.createdAt}</p>
                            <br/>
                            <li key={index}>

                                <h2>{profile.companyName}</h2>
                                <p><strong>Beschrijving:</strong> {profile.description}</p>
                                <p>
                                    <strong>Adres:</strong>
                                    {`${profile.streetName} ${profile.houseNumber}, ${profile.zipcode} ${profile.city}`}
                                </p>
                                <p><strong>Werkgebied:</strong> {profile.province}</p>
                                <p><strong>Telefoonnummer:</strong> {profile.phoneNumber}</p>
                                <p><strong>Website:</strong> {profile.website}</p>
                                <p><strong>Contactpersoon:</strong> {profile.contactPerson}</p>
                                <p><strong>Email:</strong> {profile.email}</p>
                                <p><strong>Facebook:</strong> {profile.facebookUrl}</p>
                                <p><strong>LinkedIn:</strong> {profile.linkedInUrl}</p>
                                <p><strong>Instagram:</strong> {profile.instagramUrl}</p>
                                <p><strong>TikTok:</strong> {profile.tiktokUrl}</p>
                                <p><strong>YouTube:</strong> {profile.youtubeUrl}</p>
                                <p><strong>X URL:</strong> {profile.xUrl}</p>

                        {profile.enabled ?
                            <p style={{border: 'solid 5px green'}} className="sp-admin-panel-item-paragraph-enable">Enabled</p>
                            :
                            <p style={{border: 'solid 5px red'}} className="sp-admin-panel-item-paragraph-enable">Disabled</p>
                        }


                            </li>
                        </div>

                    ))}
            </ul>

        </div>
    );
};

export default DeleteBp;

