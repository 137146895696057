import React, {useContext} from 'react';
import './Footer.css';
import Zonnemacht from "../../../assets/Zonnemacht.png";
import {Link} from "react-router-dom";
import {AuthContext} from "../../../context/AuthContext";

const Footer = () => {

    const { activeTab, setActiveTab } = useContext(AuthContext);

    const handleActiveTab = (input) => {
        setActiveTab(input);
    }

    return (
        <footer className="footer">
            <div className="footer-content">
{/*
                <div className="footer-links">
                    <ul className="footer-nav-list">

                        <li><Link to="/" onClick={() => handleActiveTab(1)} className={`header-nav-link ${activeTab === 1 ? 'active-tab' : ''}`}>Home</Link></li>


                        <li><Link to="/about" onClick={() => handleActiveTab(2)} className={` header-nav-link ${activeTab === 2 ? 'active-tab' : ''}`}>Over</Link></li>
                        <li><Link to="/contact" onClick={() => handleActiveTab(3)} className={` header-nav-link ${activeTab === 3 ? 'active-tab' : ''}`} >Contact</Link></li>

                    </ul>

                    <ul className="footer-nav-list">
                        <li><Link to="/welcome" onClick={() => handleActiveTab(1)} className={`header-nav-link ${activeTab === 1 ? 'active-tab' : ''}`}>Demo</Link></li>
                        <li><Link to="/demo" onClick={() => handleActiveTab(2)} className={` header-nav-link ${activeTab === 2 ? 'active-tab' : ''}`}>Rekentools</Link></li>
                        <li><Link to="/information" onClick={() => handleActiveTab(3)} className={` header-nav-link ${activeTab === 3 ? 'active-tab' : ''}`} >Informatie</Link></li>
                    </ul>

                </div>
*/}
            </div>

            <Link to="/" className="footer-home-link-container">
            <div className="footer-logo">
                <img
                    src={Zonnemacht}
                    alt="Zonnemacht logo"
                    style={{ width: '10rem', height: 'auto'}} // Adjust the width and height as needed
                />
                <p className="footer-logo-text">Zonnemacht</p>
            </div>
            </Link>

            <div className="footer-bottom">
                <div className="footer-toa-pp-disc">
                    <Link to="/contact" className="footer-nav-link">Contact</Link>
                    <Link to="/about" className="footer-nav-link">Over ons</Link>
                    <Link to="/information" className="footer-nav-link">Informatie</Link>

                </div>
                <div className="footer-toa-pp-disc">
                    <Link to="/terms-of-agreement" className="footer-nav-link">Algemene voorwaarden</Link>
                    <Link to="/privacy-policy" className="footer-nav-link">Privacy verklaring</Link>
                    <Link to="/disclaimer" className="footer-nav-link">Disclaimer</Link>
                </div>
                <p className="copyright">&copy; 2023 - {new Date().getFullYear()} Zonnemacht.nl is onderdeel van DevelopBit.nl</p>
            </div>
        </footer>
    );
}

export default Footer;
