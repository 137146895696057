import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PriceQuoteAdmin.css';

function PriceQuoteAdmin() {
    const [priceQuotes, setPriceQuote] = useState([]);
    const [searchUuid, setSearchUuid] = useState('');
    const [expandedTables, setExpandedTables] = useState([]); // Store the expanded state for each table

    useEffect(() => {
        const token = localStorage.getItem('token');

        async function fetchPriceQuoteData() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_HOST}/pricequote/all`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },

                });
                setPriceQuote(response.data); // Assuming data is an array of PriceQuote objects
            } catch (error) {
            }
        }

        fetchPriceQuoteData();
    }, []);


    const filteredPriceQuote = priceQuotes.filter((priceQuote) =>
        priceQuote.uuid.includes(searchUuid)
    );
    const toggleTable = (index) => {
        const updatedExpandedTables = [...expandedTables];
        updatedExpandedTables[index] = !updatedExpandedTables[index];
        setExpandedTables(updatedExpandedTables);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year}, ${hours}:${minutes}`;
    }
    return (
        <div className="admin-pricequotes-container">
            <h3 className="admin-pricequotes-title">Aanvragen</h3>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Last PriceQuote Uuid"
                    value={searchUuid}
                    onChange={(e) => setSearchUuid(e.target.value)}
                />
            </div>

            <ul >
                {filteredPriceQuote.map((priceQuote, index) => (
                    <div key={index} className="admin-pricequote-item">


                        <h3 onClick={() => toggleTable(index)}
                            style={{
                                cursor: 'pointer',
                                color: '#333',
                                fontSize: '18px',           // Larger font size
                                background: 'lightgrey',       // Yellow background
                                padding: '10px',            // Padding around the text
                                borderRadius: '5px',        // Rounded corners
                                border: '1px solid black', // Purple border
                                boxShadow: '1px 1px 3px gray', // Drop shadow
                                textAlign: 'center',

                            }}>
                            <div className="admin-pricequote-bar-title-alignment">
                                <div className="admin-pricequote-user">{ formatDate(priceQuote.createdAt)}</div>
                                <div className="admin-pricequote-user">{priceQuote.fullName}</div>
                                <div className="admin-pricequote-user">{priceQuote.email}</div>
                                <div className="admin-pricequote-user">{priceQuote.phoneNumber}</div>
                            </div>
                        </h3>

                        {expandedTables[index] && (
                            <>
                            <div className="admin-pricequote-user">{priceQuote.createdAt}</div>
                            <div className="admin-pricequote-user">{priceQuote.fullName}</div>
                            <div className="admin-pricequote-user">{priceQuote.zipcode}</div>
                            <div className="admin-pricequote-user">{priceQuote.email}</div>
                            <div className="admin-pricequote-user">{priceQuote.phoneNumber}</div>
                            <div className="admin-pricequote-user">{priceQuote.lastRequestUuid}</div>
                            </>
                        )}
                    </div>
                ))}
            </ul>
        </div>
    );
}

export default PriceQuoteAdmin;

