import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';
import Solarpanel from "./components/Solarpanel";
import './Request.css';
import { AuthContext } from "../../../../../context/AuthContext";
import CompanyAdvertisement from "../../../public/homepage/CompanyAdvertisement";
import Zonnemacht from "../../../../../assets/Zonnemacht.png";

const API_BASE_URL = process.env.REACT_APP_API_HOST;

function Request() {
    const [flatRoofPanels, setFlatRoofPanels] = useState(0);
    const [slantedRoofPanels, setSlantedRoofPanels] = useState(0);
    const [solarPanelUuid, setSolarPanelUuid] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const location = useLocation();
    // Conditionally render the CompanyAdvertisement if the route is /request
    const renderCompanyAdvertisement = location.pathname === '/request';
    const [formError, setFormError] = useState('');
    const navigate = useNavigate();
    const [isFlashing, setIsFlashing] = useState(false);
    const { isAuth } = useContext(AuthContext);
    const [solarPanels, setSolarPanels] = useState([]);

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    useEffect(() => {
        async function fetchSolarPanels() {
            try {
                const response = await axios.get(`${API_BASE_URL}/solarpanels/allavailable`);
                setSolarPanels(response.data);
            } catch (error) {
                console.error('Error fetching solar panels:', error);
            }
        }

        fetchSolarPanels();
    }, []);

    const handleSolarPanelUuid = (uuid) => {
        setSolarPanelUuid(uuid);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (flatRoofPanels <= 0 && slantedRoofPanels <= 0) {
            setFormError('Vul het aantal schuin en/of platdak panelen in');
            return;
        }

        let selectedSolarPanelUuid = solarPanelUuid; // Use a temporary variable

        if (selectedSolarPanelUuid === null) {
            // Check if solarPanelUuid is stored in localStorage
            const storedUuid = localStorage.getItem('solarPanelUuid');

            if (storedUuid === null && solarPanels.length > 0) {
                // If solarPanelUuid is not in localStorage, set it to the UUID of the first solar panel
                selectedSolarPanelUuid = solarPanels[0].uuid;
                // Save solarPanelUuid to localStorage
                localStorage.setItem('solarPanelUuid', selectedSolarPanelUuid);
                // Update the state
                setSolarPanelUuid(selectedSolarPanelUuid);
            } else if (storedUuid !== null) {
                // If solarPanelUuid is already in localStorage, use the stored value
                selectedSolarPanelUuid = storedUuid;
                // Update the state
                setSolarPanelUuid(selectedSolarPanelUuid);
            } else {
                // Handle the case where there are no solar panels
                console.error('No solar panels available.');
                return;
            }
        }

        const amsterdamTime = new Date().toLocaleString('nl-NL', { timeZone: 'Europe/Amsterdam' });

/*
        const token = localStorage.getItem('token');
*/

        const requestData = {
            flatRoofPanels,
            slantedRoofPanels,
            solarPanelUuid: selectedSolarPanelUuid, // Use the selectedSolarPanelUuid
            createdAt: amsterdamTime,
        };
/*
        if (!isAuth) {
            navigate('/register-request');
            localStorage.setItem("savedRequest", JSON.stringify(requestData));
            return;
        }*/

        try {


            const response = await axios.post(`${API_BASE_URL}/request/create`, requestData);
            const newRequest = response.data;
            // Save request.uuid to localStorage
            localStorage.setItem('lastRequestUuid', newRequest.uuid);

            setFlatRoofPanels(0);
            setSlantedRoofPanels(0);
            setSolarPanelUuid(null);
            setCreatedAt(null);
            setFormError('');

            navigate('/price-indication');
/*
            navigate('/profile/requests');
*/

        } catch (error) {
            console.error('Error submitting request:', error);
            alert('Kies een zonnepaneel en vul een veld in.');
        }
    };

    const handlePanelChange = (e, setterFunction) => {
        var input = e.target.value;
        var result = PanelNumberValidator(input);
        if (result) {
            setterFunction(input);
        } else {
            if (input.length === 1) {
                e.target.value = '';
            } else {
                e.target.value = input;
            }
        }
    };

    const handleSlantedRoofPanelsChange = (e) => {
        handlePanelChange(e, setSlantedRoofPanels);
    };

    const handleFlatRoofPanelsChange = (e) => {
        handlePanelChange(e, setFlatRoofPanels);
    };

    function PanelNumberValidator(input) {
        if (!isNaN(input)) {
            const newValue = parseInt(input, 10);
            if (newValue >= 0) {
                return true;
            }
        }
        return false;
    }

    return (

        <div className="request-container" id="request-page">
            {renderCompanyAdvertisement && (
                <CompanyAdvertisement
                    companyName="Uw bedrijfsnaam"
                    description="Adverteren op Zonnemacht.nl?"
                    website="https://zonnemacht.nl/request"
                    logoSrc={Zonnemacht}
                />
            )}
            {solarPanelUuid === null && solarPanels.length > 0 && (
                <div style={{ display: 'none' }}>
                    <Solarpanel setSolarPanelUuid={handleSolarPanelUuid} />
                </div>
            )}
            <form className="request-form" onSubmit={handleSubmit}>
                <div className="request-instructions">
                <p className="hc-request-header">Bereken het aantal zonnepanelen. </p>
{/*
                <p className="request-subheader">Vul het gewenste aantal zonnepanelen in voor schuin en/of platdak.</p>
*/}
                </div>
                <div className="form-group-alignment">
                    <div className="form-group">
                        <label className="form-label">Schuindak</label>
                        <input
                            type="text"
                            className="form-input"
                            onChange={handleSlantedRoofPanelsChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Platdak</label>
                        <input
                            type="text"
                            className="form-input"
                            onChange={handleFlatRoofPanelsChange}
                        />
                    </div>
                </div>

                {formError && <p className="form-error">{formError}</p>}
                <div className="request-submit-button-container">
                    <button className={`hc-submit-button ${isFlashing ? 'flash' : ''}`} type="submit">
                        Resultaat
                    </button>
                </div>
            </form>
            {renderCompanyAdvertisement && (
                <CompanyAdvertisement
                    companyName="Uw bedrijfsnaam"
                    description="Adverteren op Zonnemacht.nl?"
                    website="https://zonnemacht.nl/request"
                    logoSrc={Zonnemacht}
                />
            )}
        </div>

    );
}

export default Request;
