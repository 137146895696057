import React from 'react';
import {useNavigate} from "react-router-dom";
import './NewAppointment.css';

function NewAppointment() {
    const navigate = useNavigate(); // Initialize useNavigate
    const handleNewAppointmentButtonClick = () => {
        // Navigate to the ProfileSettings.js component when the button is clicked
        navigate('/schedule-appointment');
    };
    return (
        <div className="new-appointment-button-container">
            <button className="new-appointment-button" onClick={handleNewAppointmentButtonClick}>Afspraak</button>
        </div>
    );
}

export default NewAppointment;
