import React from 'react';

function SolarpanelsOffer() {
    return (
        <div>
            {/* Your application code here */}
        </div>
    );
}

export default SolarpanelsOffer;
