import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './RequestsAdmin.css';
import BackToAdminPanel from '../buttons/BackToAdminPanel';

function RequestsAdmin() {
    const [requests, setRequests] = useState([]);
    const [searchUuid, setSearchUuid] = useState('');
    const [expandedTables, setExpandedTables] = useState([]); // Store the expanded state for each table

    useEffect(() => {
        const token = localStorage.getItem('token');

        async function fetchRequestsData() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/request/all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },

            });
            setRequests(response.data); // Assuming data is an array of Request objects
        } catch (error) {
        }
    }

        fetchRequestsData();
    }, []);


    const filteredRequests = requests.filter((request) =>
        request.uuid.includes(searchUuid)
    );
    const toggleTable = (index) => {
        const updatedExpandedTables = [...expandedTables];
        updatedExpandedTables[index] = !updatedExpandedTables[index];
        setExpandedTables(updatedExpandedTables);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year}, ${hours}:${minutes}`;
    }


    return (
        <div className="admin-requests-container">
            <h3 className="admin-requests-title">Requests</h3>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Last Request Uuid"
                    value={searchUuid}
                    onChange={(e) => setSearchUuid(e.target.value)}
                />
            </div>

            <ul >
                {filteredRequests.map((request, index) => (
                    <div key={index} className="admin-request-item">


                        <h3 onClick={() => toggleTable(index)}
                            style={{
                                cursor: 'pointer',
                                color: '#333',
                                fontSize: '18px',           // Larger font size
                                background: 'lightgrey',       // Yellow background
                                padding: '10px',            // Padding around the text
                                borderRadius: '5px',        // Rounded corners
                                border: '1px solid black', // Purple border
                                boxShadow: '1px 1px 3px gray', // Drop shadow
                                textAlign: 'center',

                            }}>
                            <div className="admin-request-bar-title-alignment">
                                <div className="admin-request-user">{request.resultSolarPanelQuantity} zonnepanelen</div>
                                <div className="admin-request-user">{formatDate(request.createdAt)} </div>

                            </div>
                        </h3>

                        {expandedTables[index] && (
                        <table className="price-indication-table">
                            <thead>
                            <tr>
                                <th>Product / Dienst</th>
                                <th>Aantal</th>
                                <th>Prijs</th>
                                <th>Totaal</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{request.resultSolarPanelName ? request.resultSolarPanelName : 'N.V.T.'}</td>
                                <td>{request.resultSolarPanelQuantity ? request.resultSolarPanelQuantity : 'N.V.T.'}</td>
                                <td>{request.resultSolarPanelCost ? `€${request.resultSolarPanelCost}` : 'N.V.T.'}</td>
                                <td>{request.resultSolarPanelTotalCost ? `€${request.resultSolarPanelTotalCost}` : 'N.V.T.'}</td>
                            </tr>
                            <tr>
                                <td>Platdak montage materiaal</td>
                                <td>{request.flatRoofPanels || 0}</td>
                                <td>{request.resultFlatRoofAssemblyMaterialCost ? `€${request.resultFlatRoofAssemblyMaterialCost}` : 'N.V.T.'}</td>
                                <td>{request.resultFlatRoofAssemblyMaterialTotalCost ? `€${request.resultFlatRoofAssemblyMaterialTotalCost}` : 'N.V.T.'}</td>
                            </tr>

                            <tr>
                                <td>Schuindak montage materiaal</td>
                                <td>{request.slantedRoofPanels || 0}</td>
                                <td>{request.resultSlantedRoofAssemblyMaterialCost ? `€${request.resultSlantedRoofAssemblyMaterialCost}` : 'N.V.T.'}</td>
                                <td>{request.resultSlantedRoofAssemblyMaterialTotalCost ? `€${request.resultSlantedRoofAssemblyMaterialTotalCost}` : 'N.V.T.'}</td>
                            </tr>

                            <tr>
                                <td>Omvorming</td>
                                <td>{request.resultInversionQuantity || 0}</td>
                                <td>{request.resultInversionCost ? `€${request.resultInversionCost}` : 'N.V.T.'}</td>
                                <td>{request.resultInversionTotalCost ? `€${request.resultInversionTotalCost}` : 'N.V.T.'}</td>
                            </tr>

                            <tr>
                                <td>Verankering</td>
                                <td>{request.resultAnchoringQuantity || 0}</td>
                                <td>{request.resultAnchoringCost ? `€${request.resultAnchoringCost}` : 'N.V.T.'}</td>
                                <td>{request.resultAnchoringTotalCost ? `€${request.resultAnchoringTotalCost}` : 'N.V.T.'}</td>
                            </tr>

                            <tr>
                                <td>Meterkast wijziging</td>
                                <td>{request.resultElectricsQuantity || 0}</td>
                                <td>{request.resultElectricsCost ? `€${request.resultElectricsCost}` : 'N.V.T.'}</td>
                                <td>{request.resultElectricsTotalCost ? `€${request.resultElectricsTotalCost}` : 'N.V.T.'}</td>
                            </tr>

                            <tr>
                                <td>Levering</td>
                                <td>{request.resultDeliveryCostQuantity || 0}</td>
                                <td>{request.resultDeliveryCostCost ? `€${request.resultDeliveryCostCost}` : 'N.V.T.'}</td>
                                <td>{request.resultDeliveryCostCost ? `€${request.resultDeliveryCostCost}` : 'N.V.T.'}</td>
                            </tr>

                            <tr>
                                <td>Hoogte Transport</td>
                                <td>{request.resultHeightTransportQuantity || 0}</td>
                                <td>{request.resultHeightTransportCost ? `€${request.resultHeightTransportCost}` : 'N.V.T.'}</td>
                                <td>{request.resultHeightTransportCost ? `€${request.resultHeightTransportCost}` : 'N.V.T.'}</td>
                            </tr>

                            <tr>
                                <td>Installatie Team</td>
                                <td>{request.resultInstallationTeamQuantity || 0}</td>
                                <td>{request.resultInstallationTeamCost ? `€${request.resultInstallationTeamCost}` : 'N.V.T.'}</td>
                                <td>{request.resultInstallationTeamCost ? `€${request.resultInstallationTeamCost}` : 'N.V.T.'}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Totaal prijs</td>
                                <td>{}</td>
                                <td>{}</td>
                                <td style={{ fontWeight: 'bold' }}>{request.resultTotalCost ? `€${request.resultTotalCost}` : 'N.V.T.'}</td>

                            </tr>

                            </tbody>
                        </table>
                            )}
                    </div>
                ))}
            </ul>
        </div>
    );
}

export default RequestsAdmin;

