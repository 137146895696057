import React, {useState, useEffect} from 'react';
import axios from "axios";
import './SolarpanelAdmin.css';
import SpAdminInputField from "./helpers/spAdminInputField";

function SolarpanelAdmin() {

    const initialNewPanel = {
        uuid: "",
        brand: "",
        name: "",
        power: 0,
        price: 0,
        length: 0,
        width: 0,
        height: 0,
        uniqueSellingPoint: "",
        bestPickScenario: "",
        guaranteeInYears: 0,
        imageUrl: '',
        enabled: ''
    };

    const [solarpanels, setSolarpanels] = useState([]);
    const [newPanel, setNewPanel] = useState(initialNewPanel);
    const [selectePanel, setSelectePanel] = useState('');
    const token = localStorage.getItem('token');


    useEffect(() => {
        fetchSolarPanels();
    }, []);

    async function fetchSolarPanels() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/solarpanels/all`,{headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }});
            setSolarpanels(response.data);
        } catch (error) {
        }
    }

    const handleAddPanel = async () => {

        if(newPanel.name === '' || newPanel.brand === '' || newPanel.uniqueSellingPoint === '' || newPanel.bestPickScenario === '' || newPanel.imageUrl === ''){
            alert("Please fill all the fields and try again!");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST}/solarpanels/create`, newPanel, { // Use decoded.sub for fetching user data
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const addedPanel = response.data;
            setSolarpanels([...solarpanels, addedPanel]);
            setNewPanel(initialNewPanel); // Reset to the initial state
        } catch (error) {
        }
    };


    const handleUpdatePanel = async () => {

        if(newPanel.name === '' || newPanel.brand === '' || newPanel.uniqueSellingPoint === '' || newPanel.bestPickScenario === '' || newPanel.imageUrl === ''){
            alert("Please fill all the fields and try again!");
            return;
        }

        try{
            await axios.put(`${process.env.REACT_APP_API_HOST}/solarpanels/update`, newPanel, { // Use decoded.sub for fetching user data
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            Clear();
            await fetchSolarPanels();
        } catch (error) {
            alert("Damm erroror"+error)
        }

    };


    const handlePanelEnable = async () => {
        try{
            await axios.put(`${process.env.REACT_APP_API_HOST}/solarpanels/enable/${selectePanel}`,{},{ // Use decoded.sub for fetching user data
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            await fetchSolarPanels();

        } catch (error) {
            alert("Error: "+error)
        }
    }

    const handlePanelDisable = async () => {
        try{ //enable/{uuid}
            await axios.put(`${process.env.REACT_APP_API_HOST}/solarpanels/disable/${selectePanel}`, {}, { // Use decoded.sub for fetching user data
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            await fetchSolarPanels();
        } catch (error) {
            alert("Error: "+error)
        }
    }

    const handleSP_click = (panel) => {

        setSelectePanel(panel.uuid);
        setNewPanel({
            uuid: panel.uuid,
            brand: panel.brand,
            name: panel.name,
            power: panel.power,
            price: panel.price,
            length: panel.length,
            width: panel.width,
            height: panel.height,
            uniqueSellingPoint: panel.uniqueSellingPoint,
            bestPickScenario: panel.bestPickScenario,
            guaranteeInYears: panel.guaranteeInYears,
            imageUrl: panel.imageUrl,
            enabled: panel.enabled
        });
    };

    function Clear(){
        setSelectePanel('');
        setNewPanel(initialNewPanel);
    }



    return (

        <div className="sp-admin-container">
            <div className="sp-admin-aligned-left">
                <h3 className="sp-admin-header">
                    Huidige waarden
                </h3>
                <ul className="sp-admin-panel-list">

                    {solarpanels.map((panel, index) => (
                        <li key={index} onClick={() => handleSP_click(panel)} className={`sp-admin-panel-item ${selectePanel === panel.uuid ? "selected-panel" : ""}`}>

                            <div className="sp-admin-item-content">
                                {panel.file && (


                                    <div className="sp-admin-image-container"><img src={panel.file.url}
                                                                                   alt="Assigned Solar Panel"
                                                                                   className="sp-admin-assigned-image"/>
                                    </div>
                                )}
                                <div className="sp-admin-panel-text">

                                    <div className="sp-admin-image-alignment">
                                        <div className="panel-list-item-image">{panel.imageUrl && (
                                            <img src={panel.imageUrl} alt="Assigned Solar Panel"/>)}
                                        </div>
                                    </div>

                                    <div className="sp-admin-panel-text-alignment">

                                        {/*Built for holding the uuid only*/}
                                        <p style={{display: "none"}} className="sp-admin-panel-item-paragraph">{panel.uuid}</p>

                                        <p className="sp-admin-panel-item-paragraph">{panel.brand}</p>
                                        <p className="sp-admin-panel-item-paragraph">{panel.name}</p>
                                        <p className="sp-admin-panel-item-paragraph">{panel.power} watts</p>
                                        <p className="sp-admin-panel-item-paragraph">€{panel.price}</p>
                                        <p className="sp-admin-panel-item-paragraph">{panel.length}x{panel.width}x{panel.height} mm</p>
                                        <p className="sp-admin-panel-item-paragraph">{panel.uniqueSellingPoint}</p>
                                        <p className="sp-admin-panel-item-paragraph">{panel.bestPickScenario}</p>
                                        <p className="sp-admin-panel-item-paragraph">{panel.guaranteeInYears} jaar</p>
                                        {panel.enabled ?
                                            <p style={{border: 'solid 1px green'}} className="sp-admin-panel-item-paragraph-enable">Enabled</p>
                                        :
                                            <p style={{border: 'solid 1px red'}} className="sp-admin-panel-item-paragraph-enable">Disabled</p>
                                        }

                                    </div>

                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>


            <div className="sp-admin-aligned-right">
                <h2 className="sp-admin-crud-title">
                    Nieuwe waarden
                </h2>
                <div className="sp-admin-crud-operations">

                    <SpAdminInputField
                        label="Merk"
                        value={newPanel.brand}
                        onChange={(value) => setNewPanel({...newPanel, brand: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Naam"
                        value={newPanel.name}
                        onChange={(value) => setNewPanel({...newPanel, name: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="WattPiek"
                        value={newPanel.power}
                        onChange={(value) => setNewPanel({...newPanel, power: value})}
                        className="sp-admin-input-field-cn"

                    />
                    <SpAdminInputField
                        label="Prijs"
                        value={newPanel.price}
                        onChange={(value) => setNewPanel({...newPanel, price: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Lengte in mm"
                        value={newPanel.length}
                        onChange={(value) => setNewPanel({...newPanel, length: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Breedte in mm"
                        value={newPanel.width}
                        onChange={(value) => setNewPanel({...newPanel, width: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Dikte in mm"
                        value={newPanel.height}
                        onChange={(value) => setNewPanel({...newPanel, height: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Uniek punt"
                        value={newPanel.uniqueSellingPoint}
                        onChange={(value) => setNewPanel({...newPanel, uniqueSellingPoint: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Beste keuze scenario"
                        value={newPanel.bestPickScenario}
                        onChange={(value) => setNewPanel({...newPanel, bestPickScenario: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Garantie in jaren"
                        value={newPanel.guaranteeInYears}
                        onChange={(value) => setNewPanel({...newPanel, guaranteeInYears: value})}
                        className="sp-admin-input-field-cn"
                    />
                    <SpAdminInputField
                        label="Link"
                        value={newPanel.imageUrl}
                        onChange={(value) => setNewPanel({...newPanel, imageUrl: value})}
                        className="sp-admin-input-field-cn"
                    />

                </div>

                <div className="sp-admin-crud-buttons">

                    {selectePanel === '' ?
                        <button className="sp-admin-crud-add-button" onClick={handleAddPanel}>Add</button>
                    :
                        <>
                            <button className="sp-admin-crud-add-button" onClick={handleUpdatePanel}>Update</button>
                            <button className="sp-admin-crud-del-button"
                                    style={{backgroundColor: "blue", color: 'white'}}
                                    onClick={Clear}
                            >Clear
                            </button>
                            {newPanel.enabled ?
                                <button style={{backgroundColor: 'red'}} className="sp-admin-crud-add-button" onClick={handlePanelDisable}>Disable</button>
                            :
                                <button className="sp-admin-crud-add-button" onClick={handlePanelEnable}>Enable</button>
                            }

                        </>
                    }
                </div>

                {/*End of sp-admin-crud-operations*/}
            </div>
            {/*<BackToAdminPanel/>*/}
        </div>
    );
}

export default SolarpanelAdmin;

/*
    const displayPanel = (panel) => {
        if (!panel) {
            return <p>Loading...</p>;
        }
    };
*/


{/*
            <br/>
            <RedirectToSpAssignPhotoAdmin />
*/
}
