import React from 'react';
import {useNavigate} from "react-router-dom";
import './BackToAdminPanel.css';

function BackToAdminPanel() {
    const navigate = useNavigate(); // Initialize useNavigate
    const handleBackToAdminPanelButtonClick = () => {
        // Navigate to the AdminPanel.js component when the button is clicked
        navigate('/admin-panel');
    };
    return (
        <div className="back-to-my-profile-container">
            <button className="profile-back-to-my-profile" onClick={handleBackToAdminPanelButtonClick}>Ga terug</button>
        </div>
    );
}

export default BackToAdminPanel;
//TODO This branche can be used to fetch inactive buttons