import React from 'react';
import {useNavigate} from "react-router-dom";
import './BackToMyProfile.css';

function BackToMyProfile() {
    const navigate = useNavigate(); // Initialize useNavigate
    const handleBackToMyProfileButtonClick = () => {
        // Navigate to the Profile.js component when the button is clicked
        navigate('/profile');
    };
    return (
        <div className="back-to-my-profile-container">
            <button className="profile-back-to-my-profile" onClick={handleBackToMyProfileButtonClick}>Ga terug</button>
        </div>
    );
}

export default BackToMyProfile;
