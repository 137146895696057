import React from 'react';
import './spAdminInputField.css';

const SpAdminInputField = ({ label, value, onChange, className }) => {
    return (
        <div className={`${className} sp-input-container`}>
            <label className="sp-admin-input-field-label">{label}: </label>
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default SpAdminInputField;
