import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css'; // Import your CSS file
import { AuthContext } from '../context/AuthContext';

//TODO Add login options for using Google, Facebook, LinkedIn


function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, toggleError] = useState(false);
    const { login, isAuth } = useContext(AuthContext);
    const navigate = useNavigate();

/*    // useEffect to check if the user is already authenticated
    useEffect(() => {
        if (isAuth) {
            navigate('/request'); // Redirect to /request if logged in
        }
    }, [isAuth, navigate]);*/

    async function handleSubmit(e) {
        e.preventDefault();
        toggleError(false);

        try {
            const result = await axios.post(`${process.env.REACT_APP_API_HOST}/authenticate`, {
                username: username,
                password: password,
            });

            login(result.data.jwt);
            const savedRequest = localStorage.getItem("savedRequest");
            if(savedRequest !== null){
                navigate('/request');
                return;
            }
            navigate('/admin-panel');
        } catch (e) {
            toggleError(true);
        }
    }

    return (
        <div className="login-container">
            <h3 className="login-title">Inloggen</h3>
            <div className="login-section">
                    <p className="login-description">Vul je e-mailadres en wachtwoord in</p>
                    <form onSubmit={handleSubmit} className="login-form">
                        <label htmlFor="email-field" className="login-label">
                            Emailadres:
                            <input
                                type="email"
                                id="email-field"
                                name="email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="login-input"
                            />
                        </label>
                        <label htmlFor="password-field" className="login-label">
                            Wachtwoord:
                            <input
                                type="password"
                                id="password-field"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="login-input"
                            />
                        </label>
                        {error && (<p className="login-error">Combinatie van emailadres en wachtwoord is onjuist</p>)}
                        <button type="submit" className="login-button">Inloggen</button>
                    </form>
                    <p className="redirect-to-register">Of maak <Link to="/register-page">een gratis account</Link> </p>
            </div>
        </div>
    );
}

export default Login;



{/*
            {isAuth ? (
                <p>You are already logged in. Redirecting to /request...</p>
            ) : (
*/}