import React, { useState } from 'react';
import './InstallationOffer.css';
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from 'react';
import Zonnemacht from '../../../../assets/Zonnemacht.png';
import Facebook from '../../../../assets/Facebook.png';
import Instagram from '../../../../assets/Instagram.png';
import LinkedIn from '../../../../assets/LinkedIn.png';
import TikTok from '../../../../assets/TikTok.png';
import X from '../../../../assets/X.png';
import {TbWorldWww} from "react-icons/tb";
import {IoCallOutline, IoLocationOutline} from "react-icons/io5";
import {CiMail} from "react-icons/ci";
import {RiMapPinRangeLine} from "react-icons/ri";
import CompanyAdvertisement from "./CompanyAdvertisement";
/*
import testimg from '../assets/testimg.png';
*/

const InstallationOffer = () => {
    const [businessProfiles, setBusinessProfiles] = useState([]);
    const navigate = useNavigate();
    const [selectedProvince, setSelectedProvince] = useState('');
    const location = useLocation();
    // Conditionally render the CompanyAdvertisement if the route is /installation-offer
    const renderCompanyAdvertisement = location.pathname === '/installation-offer';

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);



    const provinces = [
        'Nederland',
        'Groningen',
        'Friesland',
        'Drenthe',
        'Overijssel',
        'Flevoland',
        'Gelderland',
        'Utrecht',
        'Noord-Holland',
        'Zuid-Holland',
        'Zeeland',
        'Noord-Brabant',
        'Limburg',
    ];
    const fetchBusinessProfiles = async (province) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/businessprofile/all?province=${province}`);
            setBusinessProfiles(response.data);
        } catch (error) {
            console.error('Error fetching business profiles:', error);
            // Handle error, e.g., display an error message to the user
        }
    };

    useEffect(() => {
        fetchBusinessProfiles(selectedProvince);
    }, [selectedProvince]);

    return (
        <div className="installation-offer-container">
            {renderCompanyAdvertisement && (
                <CompanyAdvertisement
                    companyName="Uw bedrijfsnaam"
                    description="Adverteren op Zonnemacht.nl?"
                    website="https://zonnemacht.nl/request"
                    logoSrc={Zonnemacht}
                />
            )}
            {renderCompanyAdvertisement && (
                <CompanyAdvertisement
                    companyName="Uw bedrijfsnaam"
                    description="Adverteren op Zonnemacht.nl?"
                    website="https://zonnemacht.nl/request"
                    logoSrc={Zonnemacht}
                />
            )}

            <h1 id="installation-offer-section">U bekijkt aanbieders in: {selectedProvince || 'Nederland'}</h1>
            <label htmlFor="province">Selecteer provincie:</label>
            <select
                id="province"
                onChange={(e) => setSelectedProvince(e.target.value)}
                value={selectedProvince}
            >
                <option value="">Kies provincie</option>
                {provinces.map((province) => (
                    <option key={province} value={province}>
                        {province}
                    </option>
                ))}
            </select>
            <ul>
                {businessProfiles
                    .filter((company) => !selectedProvince || selectedProvince === 'Nederland' || company.province.includes(selectedProvince)) // Adjusted filter condition
                    .sort((a, b) => (a.paid && !b.paid ? -1 : b.paid && !a.paid ? 1 : 0)) // Sort by paid status, paid companies first
                    .map((company, index) => (
                        <li key={index}>
                            <div className="installation-company-card">
                                <div className="name-logo-description">
                                    <h2>{company.companyName}</h2>
                                    <p>{company.description}</p>
                                </div>

                                {company.paid && (
                                    <div className="company-details">
                                        <p><strong><IoCallOutline style={{fontSize: '20px'}}/></strong> <a
                                            href={`tel:${company.phoneNumber}`}>{company.phoneNumber}</a></p>
                                        <p><strong><TbWorldWww style={{fontSize: '20px'}}/></strong> <a
                                            href={company.website} target="_blank"
                                            rel="noopener noreferrer">{company.website}</a></p>
                                        <p><strong><CiMail style={{fontSize: '20px'}}/></strong> <a
                                            href={`mailto:${company.email}`}>{company.email}</a></p>
                                        <p>
                                            <strong><IoLocationOutline style={{fontSize: '20px'}}/></strong>
                                            {`${company.streetName} ${company.houseNumber}, ${company.zipcode} ${company.city}`}
                                        </p>
                                    </div>
                                )}

                                <div className="active-service-area">
                                    <h3>Werkgebied</h3>
                                    <p><strong><RiMapPinRangeLine
                                        style={{fontSize: '20px'}}/></strong> {company.province}</p>
                                </div>
                                {company.paid && (
                                <div className="company-socials">
                                    {company.facebookUrl && (
                                        <a href={company.facebookUrl} target="_blank" rel="noopener noreferrer">
                                            <img src={Facebook} alt="Facebook"/>
                                        </a>
                                    )}
                                    {company.xUrl && (
                                        <a href={company.xUrl} target="_blank" rel="noopener noreferrer">
                                            <img src={X} alt="X (Twitter)"/>
                                        </a>
                                    )}
                                    {company.linkedInUrl && (
                                        <a href={company.linkedInUrl} target="_blank" rel="noopener noreferrer">
                                            <img src={LinkedIn} alt="LinkedIn"/>
                                        </a>
                                    )}
                                    {company.instagramUrl && (
                                        <a href={company.instagramUrl} target="_blank" rel="noopener noreferrer">
                                            <img src={Instagram} alt="Instagram"/>
                                        </a>
                                    )}
                                    {company.tiktokUrl && (
                                        <a href={company.tiktokUrl} target="_blank" rel="noopener noreferrer">
                                            <img src={TikTok} alt="TikTok"/>
                                        </a>
                                    )}
                                </div>
                                )}

                            </div>
                        </li>
                    ))}
            </ul>
            {renderCompanyAdvertisement && (
                <CompanyAdvertisement
                    companyName="Uw bedrijfsnaam"
                    description="Adverteren op Zonnemacht.nl?"
                    website="https://zonnemacht.nl/request"
                    logoSrc={Zonnemacht}
                />
            )}
            {renderCompanyAdvertisement && (
                <CompanyAdvertisement
                    companyName="Uw bedrijfsnaam"
                    description="Adverteren op Zonnemacht.nl?"
                    website="https://zonnemacht.nl/request"
                    logoSrc={Zonnemacht}
                />
            )}
        </div>
    );
};

export default InstallationOffer;

{/*
                            <img src={testimg} alt=""/>
*/}
