import React from 'react';
import './Information.css';
import {ArticleCard, Share} from "./articles/Helper/Article";
import ZonnemachtWhiteBackground from "../../../../assets/ZonnemachtWhiteBackground.png"

function Information() {

    return (
        <div className="information-page-container">
            <header className="article-page-header">
                <h1>Informatie</h1>
                <h2>Energietransitie</h2>
            </header>

            <section className="article-list">
            <Share/>

{/*
                 List of articles or links to individual articles
                <ArticleCard Link={"/information/installation-price-calculator"}
                             ImageUrl={ZonnemachtWhiteBackground} Header={"Zonnepanelen installatie rekentool"}
                             Description={"In dit artikel leest u meer over hoe een zonnepanelen installatie prijs is opgebouwd. Met de Zonnemacht zonnepanelen-installatie prijsindicatie rekentool genereert u in 30 seconden een accuraat prijs-overzicht."}
                />
*/}

                <ArticleCard Link={"/information/solar-energy-billing"}
                             ImageUrl={ZonnemachtWhiteBackground} Header={"Zonne-stroom verrekenen"}
                             Description={"Met de afbouw van de salderingsregeling nabij is het handig om nu te begrijpen hoe de particulier nu nog profiteert van zonnestroom. Dit artikel schetst makkelijk te begrijpen voorbeelden. Je kunt er ook voor kiezen om de Zonnemacht rekentool voor Terugverdientijd te benuttigen. Die is up-to-date met de nieuwste regels en genereert een compleet jaarlijks rapport."}
                />

{/*
                <ArticleCard Link={"/information/welcome-to-zonnemacht"}
                             ImageUrl={ZonnemachtWhiteBackground} Header={"Welkom bij Zonnemacht"}
                             Description={"Welkom bij Zonnemacht. In dit artikel: meer over Zonnemacht. Handig: Navigatiepaal !"}
                />

                <ArticleCard Link={"/information/calculator-and-appointment"}
                             ImageUrl={ZonnemachtWhiteBackground} Header={"Rekentool en gratis afspraak op locatie"}
                             Description={"Leer meer over hoe Zonnemacht haar uiterste best doet de energietransitie voor iedereen toegankelijk te maken met haar rekentools en eenvoudige afspraak-pagina waar je een datum en tijdstip kan selecteren voor gratis technische inspectie op locatie of informatief gesprek over de telefoon."}
                />
                <ArticleCard Link={"/demo"}
                             ImageUrl={ZonnemachtWhiteBackground} Header={"Probeer demo rekentools"}
                             Description={"Bekijk in één oog-opslag welke voordelen onze gratis rekentools jou te bieden hebben en probeer ook direct een demo!"}
                />

*/}
                {/*Commented by Khem to make the article part*/}

                {/*<article>*/}
                {/*    <h2><a href="/information/installation-price-calculator">Zonnepanelen installatie rekentool</a></h2>*/}
                {/*    <p>Gebruik de rekentool om een prijs indicatie voor zonne stroom installatie te genereren.</p>*/}
                {/*</article>*/}

                {/*<article>*/}
                {/*    <h2><a href="/information/solar-energy-billing">Zonne-stroom verrekenen</a></h2>*/}
                {/*    <p>Leer over het einde van de salderingsregeling en het verrekenen van stroom.</p>*/}
                {/*</article>*/}






                {/*Previously commented*/}

                {/*
                <article>
                    <h2><a href="/solar-panel-installation">Wat kost een warmtepomp met zonnepanelen</a></h2>
                    <p>Ontdek hoeveel een warmtepomp kost met zonnepanelen</p>
                </article>

                <article>
                    <h2><a href="/solar-panel-installation">Opbrengst zonnepanelen</a></h2>
                    <p>Ontdek hoeveel een warmtepomp kost met zonnepanelen</p>
                </article>*/}
                {/* Add more articles or links as needed */}



            </section>
        </div>
    );
}

export default Information;

