import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Header.css';
import Zonnemacht from '../../../assets/Zonnemacht.png';
import {AuthContext} from "../../../context/AuthContext";

function Header() {
    const {isAuth, isNotAuth} = useContext(AuthContext);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        // Scroll to the top of the page first
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        // Then open/close the menu
        setMenuOpen(!isMenuOpen);
    };

    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const offset = 100; // Adjust this value as needed
            const elementPosition = element.offsetTop - offset;
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth'
            });
        }
    };


    const handleLinkClick = (id) => {
        toggleMenu(); // Close the menu when a link is clicked
        scrollTo(id); // Scroll to the corresponding section
    };



    return (
        <header className={`m-header ${isMenuOpen ? 'open' : ''}`}>
            <Link to="/" onClick={() => scrollTo('hero-section')}>
                <div className="title-and-menu">
                    <h1>Installatieprijs</h1>
                    <img src={Zonnemacht} alt="Zonnemacht logo"/>
                </div>
            </Link>
            <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <ul>
{/*
                    <li><Link to="/" onClick={() => handleLinkClick('hero-section')}>Home</Link></li>

                    <br/>
                    <br/>
*/}
                    <li><Link to="/request" onClick={() => handleLinkClick('request-page')}>Prijsindicatie</Link></li>
                    <li><Link to="/information" onClick={() => handleLinkClick('request-page')}>Informatie</Link></li>
                    <li><Link to="/information/solar-energy-billing" onClick={() => handleLinkClick('request-page')}>Terugverdientijd</Link></li>


                    {/*
                    <li><Link to="/installation-offer" onClick={() => handleLinkClick('installation-offer-section')}>Installateurs</Link>
                    </li>
                    <br/>
                    <br/>
                    <li><Link to="/create/businessprofile" onClick={() => handleLinkClick('create-bp-section')}>Voor
                        aanbieders</Link></li>
*/}
                </ul>

                <ul className="profile-logout-group">
                    {isAuth && (
                        <ul>
                            <br/>
                            <br/>
                            <li><Link to="/admin-panel" className="drop-down-links">Admin</Link></li>
                        </ul>
                    )}
                    {isAuth && (
                        <div>
                            <button
                                type="button"
                                onClick={isNotAuth}
                                className="nav-login-button"
                            >
                                Log uit
                            </button>
                        </div>
                    )}
                    {!isAuth && (
                        <ul className="profile-logout-group">
                            {/* Login and registration links */}
                        </ul>
                    )}
                </ul>

            </nav>


        </header>
    );
}

export default Header;






