import React, {createContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext({});

function AuthContextProvider({children}) {
    const [isAuth, toggleIsAuth] = useState({
        isAuth: false,
        user: null,
        status: 'pending',
    });

    const [adminSelect, setAdminSelect] = useState('');
/*
    const [activeTab, setActiveTab] = useState(1);
*/

    const navigate = useNavigate();

    //MOUNTING EFFECT
    useEffect(() => {
        // haal de JWT op uit Local Storage
        const token = localStorage.getItem('token');

        // als er WEL een token is, haal dan opnieuw de gebruikersdata op
        if (token) {
            try {
                const decoded = jwt_decode(token);
                if (decoded.exp * 1000 < Date.now()) {
                    isNotAuth();
                } else {
                    toggleIsAuth({
                        isAuth: true,
                        user: decoded.user,
                        status: 'done',
                    });
                }
            } catch (e) {
                isNotAuth()
            }
        } else {
            toggleIsAuth({
                isAuth: false,
                user: null,
                status: 'done',
            });
        }
    }, []);

    function login(JWT) {
        // zet de token in de Local Storage
        localStorage.setItem('token', JWT);
        // decode de token zodat we de ID van de gebruiker hebben en data kunnen ophalen voor de context
        const decoded = jwt_decode(JWT);

        toggleIsAuth({
            isAuth: true,
            user: decoded.user,
            status: 'done',
        });

    }

    function isNotAuth() {
        localStorage.clear();
        toggleIsAuth({
            isAuth: false,
            user: null,
            status: 'done',
        });

        navigate('/login');
    }

    const contextData = {
        ...isAuth,
        login,
        isNotAuth,
        adminSelect,
        setAdminSelect,
/*
        activeTab,
*/
/*
        setActiveTab
*/
    };

    return (
        <AuthContext.Provider value={contextData}>
            {isAuth.status === 'done' ? children : <p>Loading...</p>}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
