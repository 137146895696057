// PriceQuote.js

import React, { useState } from 'react';
import './PriceQuote.css';
import ZonnemachtWhiteBackground from '../../../../../../assets/ZonnemachtWhiteBackground.png';
import axios from "axios";
import {useNavigate} from "react-router-dom"; // Replace with the actual path to your logo file

const PriceQuote = () => {
    const [fullName, setFullName] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [consent, setConsent] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Check local storage for lastRequestUuid
            const lastRequestUuid = localStorage.getItem('lastRequestUuid');

            // Prepare the data object with form values
            const formData = {
                fullName,
                zipcode,
                email,
                phoneNumber,
                consent,
                lastRequestUuid, // Include lastRequestUuid in formData
            };

            // Make the POST request using Axios
            const response = await axios.post(`${process.env.REACT_APP_API_HOST}/pricequote/create`, formData);

            // Update local storage with the new lastRequestUuid
            localStorage.setItem('lastRequestUuid', response.data.lastRequestUuid);
            navigate('/pq-approval-page');

        } catch (error) {
        }
    };





    return (
        <div className="price-quote-container">
            <img src={ZonnemachtWhiteBackground} alt="Zonnemacht-logo-wit-achtergrond" className="price-quote-logo" />
            <h2 className="price-quote-header">Offerte aanvragen</h2>
            <form className="price-quote-form" onSubmit={handleSubmit}>
                <label className="price-quote-label">
                    Volledige naam:
                    <input
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                    />
                </label>

                <label className="price-quote-label">
                    Postcode:
                    <input
                        type="text"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                        required
                    />
                </label>

                <label className="price-quote-label">
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>

                <label className="price-quote-label">
                    Telefoonnummer:
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                </label>

                <label>
                    <input
                        type="checkbox"
                        checked={consent}
                        onChange={(e) => setConsent(e.target.checked)}
                        required
                    />
                    Ik ga akkoord met de algemene voorwaarden & privacy verklaring van www.zonnemacht.nl.
                </label>

                <button className="price-quote-submit" type="submit">
                    Geheel vrijblijvend een offerte aanvragen
                </button>
            </form>
        </div>
    );
};

export default PriceQuote;

