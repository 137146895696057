import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UpdateBp.css';
import {useNavigate} from "react-router-dom";

const UpdateBp = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    // State to hold the business profile data
    const [businessProfile, setBusinessProfile] = useState({
        uuid: "",
        companyName: "",
        description: "",
        streetName: "",
        houseNumber: "",
        zipcode: "",
        city: "",
        province: "",
        category: "",
        phoneNumber: "",
        website: "",
        contactPerson: "",
        email: "",
        facebookUrl: "",
        linkedInUrl: "",
        instagramUrl: "",
        tiktokUrl: "",
        youtubeUrl: "",
        xUrl: "",
        paid: ""
    });

    // Fetch business profile data on component mount
    useEffect(() => {
        // Retrieve uuid from localStorage
        const uuidFromLocalStorage = localStorage.getItem('updateBpUuid');

        // Check if uuid is present
        if (uuidFromLocalStorage) {
            // Make a GET request to retrieve the current business profile data
            axios.get(`${process.env.REACT_APP_API_HOST}/businessprofile/${uuidFromLocalStorage}`)
                .then(response => {
                    // Set the retrieved data to the state
                    setBusinessProfile(response.data);
                })
                .catch(error => {
                    console.error('Error fetching business profile data:', error);
                });
        }
    }, []);

    // Function to handle updates to the business profile
    const handleUpdate = async () => {
        try {
            console.log('Data sent', businessProfile);

            // Make the PUT request to update the business profile
            const response = await axios.put(
                `${process.env.REACT_APP_API_HOST}/businessprofile/update`,
                businessProfile,
                {
                    headers: {
                        // Add any necessary headers here, such as authorization headers
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            // Handle success, you can log or show a success message
            console.log('Business profile updated successfully:', response.data);
        } catch (error) {
            // Handle errors, you can log or show an error message
            console.error('Error updating business profile:', error);
        }
    };

    // Function to handle changes in form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBusinessProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleReturn = () => {
        navigate('/admin-panel/validate-bp');
    };
    return (
        <div className="update-bp-container">
            <div className="form-container">
            {/* Render your form fields here */}
                <label htmlFor="uuid">UUID:</label>
                <input type="text" id="uuid" name="uuid" value={businessProfile.uuid} disabled />

                <label htmlFor="companyName">Company Name:</label>
                <input type="text" id="companyName" name="companyName" value={businessProfile.companyName} onChange={handleChange} />

                <label htmlFor="description">Description:</label>
                <input type="text" id="description" name="description" value={businessProfile.description} onChange={handleChange} />

                <label htmlFor="streetName">Street Name:</label>
                <input type="text" id="streetName" name="streetName" value={businessProfile.streetName} onChange={handleChange} />

                <label htmlFor="houseNumber">House Number:</label>
                <input type="text" id="houseNumber" name="houseNumber" value={businessProfile.houseNumber} onChange={handleChange} />

                <label htmlFor="zipcode">Zipcode:</label>
                <input type="text" id="zipcode" name="zipcode" value={businessProfile.zipcode} onChange={handleChange} />

                <label htmlFor="city">City:</label>
                <input type="text" id="city" name="city" value={businessProfile.city} onChange={handleChange} />

                <label htmlFor="phoneNumber">Phone Number:</label>
                <input type="tel" id="phoneNumber" name="phoneNumber" value={businessProfile.phoneNumber} onChange={handleChange} />

                <label htmlFor="website">Website:</label>
                <input type="url" id="website" name="website" value={businessProfile.website} onChange={handleChange} />

                <label htmlFor="contactPerson">Contact Person:</label>
                <input type="text" id="contactPerson" name="contactPerson" value={businessProfile.contactPerson} onChange={handleChange} />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={businessProfile.email} onChange={handleChange} />

                <label htmlFor="province">Province:</label>
                <input type="text" id="province" name="province" value={businessProfile.province} onChange={handleChange} />

                <label htmlFor="category">Category:</label>
                <input type="text" id="category" name="category" value={businessProfile.province} onChange={handleChange} />

                <label htmlFor="facebookUrl">Facebook URL:</label>
                <input type="url" id="facebookUrl" name="facebookUrl" value={businessProfile.facebookUrl} onChange={handleChange} />

                <label htmlFor="linkedInUrl">LinkedIn URL:</label>
                <input type="url" id="linkedInUrl" name="linkedInUrl" value={businessProfile.linkedInUrl} onChange={handleChange} />

                <label htmlFor="instagramUrl">Instagram URL:</label>
                <input type="url" id="instagramUrl" name="instagramUrl" value={businessProfile.instagramUrl} onChange={handleChange} />

                <label htmlFor="tiktokUrl">TikTok URL:</label>
                <input type="url" id="tiktokUrl" name="tiktokUrl" value={businessProfile.tiktokUrl} onChange={handleChange} />

                <label htmlFor="youtubeUrl">YouTube URL:</label>
                <input type="url" id="youtubeUrl" name="youtubeUrl" value={businessProfile.youtubeUrl} onChange={handleChange} />

                <label htmlFor="xUrl">X URL:</label>
                <input type="url" id="xUrl" name="xUrl" value={businessProfile.xUrl} onChange={handleChange} />

                <label htmlFor="paid">Paid:</label>
                <input type="boolean" id="paid" name="paid" value={businessProfile.paid} onChange={handleChange} />

                {/* For simplicity, I'm just showing the button to trigger the update */}
                <button onClick={handleUpdate}>Update Business Profile</button>
                <br/>
                <button className="return-button" onClick={handleReturn}>
                    Overzicht
                </button>
            </div>
        </div>
    );
};

export default UpdateBp;
