import React, {useEffect, useState} from 'react';
import { useCookies } from 'react-cookie';
import './CookieConsent.css';
const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const [cookieShowed, setCookieShowed] = useCookies(["cookieShown"]);

    const [showCookieBox, setShowCookieBox] = useState(false);

    /*These TODOs are written while on call with Google technical solutions team */
    //TODO Missing default mode; useEffect { default === Denied } == USER HAS NOT CLICKED ACCEPT/DENY
    //TODO useEffect { defaultmode= denied }
    //TODO Vanuit de website naar Gtag
    //TODO if user { No } === default/defnied
    //TODO if user { Yes} { Send update to Gtag "Ads-storage allowed en Analytics-storage allowed


    /*
    * I am not sure if I have done all the thing you said in the todos but so far in the cookieConsent component
    *
    * 1. I have made the default value of cookie consent as denied
    * 2. I have added another cookie to keep track if the cookie box was shown or if decision was made
    * 3. The useState will take care of showing cookie box when needed
    *
    * */


    useEffect(()=>{

        if(cookieShowed.cookieShown === undefined){
            setShowCookieBox(true);
        }

        // Check if the user has given consent to display the box
        if(cookies.cookieConsent === undefined){
            setCookie("cookieConsent", false, { path: "/", expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
        }
    },[]);



    const giveCookieConsent = () => {
        setCookie("cookieConsent", true, { path: "/", expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
        decisionMade(true);
    };

    const declineCookieConsent = () => {
        // Set a cookie with a value indicating that the user has declined consent.
        setCookie("cookieConsent", false, { path: "/", expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
        decisionMade(true);
    };

    const decisionMade = () => {
        setCookieShowed("cookieShown", true, { path: "/", expires: new Date(Date.now() + 2 * 365 * 24 * 60 * 60 * 1000) });
        setShowCookieBox(false);
    }

    return (
        <div className={`cookie-consent-box ${showCookieBox ? '' : 'cookie-dont-show'}`}>
            <div className="cookie-consent-box-aligned-start">
                <p>Zonnemacht gebruikt cookies om de website te verbeteren. Analytische cookies kunt u uitzetten. <a href={"/privacy-policy"}>Lees meer over ons cookiebeleid.</a></p>
            </div>
            <div className="cookie-consent-box-aligned-end">
                <button className="cookie-consent-box-accept" onClick={giveCookieConsent}>Accepteer cookies</button>
                <button className="cookie-consent-box-deny" onClick={declineCookieConsent}>Tracking cookies uitzetten</button>
            </div>
        </div>
    );
};

export default CookieConsent;
