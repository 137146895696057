import React from 'react';
import './CompanyAdvertisement.css';

const CompanyAdvertisement = ({ logoSrc, companyName, description, website }) => {
    return (
        <div className="company-advertisement">
            <div className="company-logo">
                <img src={logoSrc} alt="Company Logo" />
            </div>
            <div className="company-info">
                <h3>{companyName}</h3>
                <p>{description}</p>
                <a href={website} target="_blank" rel="noopener noreferrer">Bezoek Website</a>
            </div>
        </div>
    );
};

export default CompanyAdvertisement;
