import React from 'react';
import './BpSuccessfulPage.css';
import {useNavigate} from "react-router-dom"; // Import your CSS styles if needed

const BpSuccessfulPage = () => {
    /*
        const navigate = useNavigate();
    
        const handleLearnMoreClick = () => {
            // Redirect to the /information route
            navigate('/information');
        };
    */


    return (
        <div className="bp-successful-page-container">
            <h2 className="bp-successful-page-header">Gelukt!</h2>
            <p className="bp-successful-page-message">
                Je hebt gratis jouw aanbieder aangemeld.
            </p>
            <p className="bp-successful-page-message">
                Wij valideren nu handmatig (max 1 werkdag) de aanbieder.
            </p>

            {/*            <button className="learn-more-button" onClick={handleLearnMoreClick}>
                Lees meer over zonne-energie...
            </button>*/}
        </div>
    );
};

export default BpSuccessfulPage;
