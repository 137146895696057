import React, { useState } from 'react';
import './CreateBusinessProfile.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {useEffect} from 'react';
import CompanyAdvertisement from "./CompanyAdvertisement"; // Import useNavigate
import Zonnemacht from '../../../../assets/Zonnemacht.png';

const CreateBusinessProfile = () => {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const [formData, setFormData] = useState({
        companyName: '',
        description: '',
        streetName: '',
        houseNumber: '',
        zipcode: '',
        city:'',
        province: [],
/*
        category: [],
*/
        phoneNumber: '',
        website: '',
        contactPerson: '',
        email: '',
        facebookUrl: '',
        linkedInUrl: '',
        instagramUrl: '',
        tiktokUrl: '',
        youtubeUrl: '',
        xUrl: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevData) => {
            if (type === 'checkbox') {
                let updatedValues;

                if (value === 'Nederland' && checked) {
                    updatedValues = ['Nederland'];
                    const otherProvinces = [
                        'Groningen',
                        'Friesland',
                        'Drenthe',
                        'Overijssel',
                        'Flevoland',
                        'Gelderland',
                        'Utrecht',
                        'Noord-Holland',
                        'Zuid-Holland',
                        'Zeeland',
                        'Noord-Brabant',
                        'Limburg',
                    ];
                    otherProvinces.forEach((province) => {
                        document.querySelector(`[value="${province}"]`).disabled = true;
                    });
                } else if (value === 'Nederland' && !checked) {
                    updatedValues = [];
                    const otherProvinces = [
                        'Groningen',
                        'Friesland',
                        'Drenthe',
                        'Overijssel',
                        'Flevoland',
                        'Gelderland',
                        'Utrecht',
                        'Noord-Holland',
                        'Zuid-Holland',
                        'Zeeland',
                        'Noord-Brabant',
                        'Limburg',
                    ];
                    otherProvinces.forEach((province) => {
                        const checkbox = document.querySelector(`[value="${province}"]`);
                        checkbox.disabled = false;
                        checkbox.checked = false;
                    });
                } else {
                    const currentProvinces = Array.isArray(prevData.provinces) ? prevData.provinces : [];
                    updatedValues = checked
                        ? [...currentProvinces, value]
                        : currentProvinces.filter((province) => province !== value);

                    const provinces = [
                        'Groningen',
                        'Friesland',
                        'Drenthe',
                        'Overijssel',
                        'Flevoland',
                        'Gelderland',
                        'Utrecht',
                        'Noord-Holland',
                        'Zuid-Holland',
                        'Zeeland',
                        'Noord-Brabant',
                        'Limburg',
                    ];

                    provinces.forEach((province) => {
                        document.querySelector(`[value="${province}"]`).disabled = false;
                    });
                }

                const provincesString = updatedValues.join(', ');

                return { ...prevData, [name]: updatedValues, provinces: updatedValues, province: provincesString };
            } else {
                return { ...prevData, [name]: value };
            }
        });
    };

    const handleChangeCategory = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevData) => {
            if (type === 'checkbox') {
                let updatedValues;

                if (value === 'Nederland' && checked) {
                    updatedValues = ['Nederland'];
                    const otherCategories = [
                        'BouwRenovatie',
                        'Installatie',
                        'IT',
                        'Recruitment',
                        'Marketing',
                        'Producten',
                        'Machines',
                        'Zorg',
                        'Logistiek',
                    ];
                    otherCategories.forEach((category) => {
                        document.querySelector(`[value="${category}"]`).disabled = true;
                    });
                } else {
                    const currentCategories = Array.isArray(prevData.categories) ? prevData.categories : [];
                    updatedValues = checked
                        ? [...currentCategories, value]
                        : currentCategories.filter((category) => category !== value);

                    const categories = [
                        'BouwRenovatie',
                        'Installatie',
                        'IT',
                        'Recruitment',
                        'Marketing',
                        'Producten',
                        'Machines',
                        'Zorg',
                        'Logistiek',
                    ];
                    categories.forEach((category) => {
                        document.querySelector(`[value="${category}"]`).disabled = false;
                    });
                }

                const categoriesString = updatedValues.join(', ');

                return { ...prevData, [name]: updatedValues, categories: updatedValues, category: categoriesString };
            } else {
                return { ...prevData, [name]: value };
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
/*
// Check if at least one category is selected
        if (formData.category.length === 0) {
            alert('Selecteer minimaal één categorie.'); // You can replace this with your own error handling logic
            return;
        }
*/

        // Check if at least one province is selected
        if (formData.province.length === 0) {
            alert('Selecteer minimaal één provincie.'); // You can replace this with your own error handling logic
            return;
        }
        try {
            console.log('Form Data:', formData);

            const response = await axios.post(`${process.env.REACT_APP_API_HOST}/businessprofile/create`, formData);

            if (response.status === 200 || response.status === 201) {
                navigate('/create/businessprofile/successful');
            }

            const provinces = [
                'Groningen',
                'Friesland',
                'Drenthe',
                'Overijssel',
                'Flevoland',
                'Gelderland',
                'Utrecht',
                'Noord-Holland',
                'Zuid-Holland',
                'Zeeland',
                'Noord-Brabant',
                'Limburg',
            ];
            provinces.forEach((province) => {
                document.querySelector(`[value="${province}"]`).disabled = false;
            });

            const categories = [
                'BouwRenovatie',
                'Installatie',
                'IT',
                'Recruitment',
                'Marketing',
                'Producten',
                'Machines',
                'Zorg',
                'Logistiek',
            ];
            categories.forEach((category) => {
                document.querySelector(`[value="${category}"]`).disabled = false;
            });

            setFormData({
                companyName: '',
                description: '',
                streetName: '',
                houseNumber: '',
                zipcode: '',
                city: '',
                province: [],
/*
                category: [],
*/
                phoneNumber: '',
                website: '',
                contactPerson: '',
                email: '',
                facebookUrl: '',
                linkedInUrl: '',
                instagramUrl: '',
                tiktokUrl: '',
                youtubeUrl: '',
                xUrl: '',
            });
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    return (
        <div className="installation-c-form-container" id="create-bp-section">
            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />

            <h1>Aanmeldformulier</h1>

            <h2 style={{ borderBottom: '2px solid #000' }}>Meld jouw aanbieder aan voor gratis regelvermelding op Zonnemacht.nl.</h2>
            <h2>Je kunt ook kiezen voor een betaalde vermelding. Daarbij worden alle bedrijfsgegevens weergeven. Neem daarvoor contact op met contact@zonnemacht.nl</h2>
            <br />
            <h3>Naam en omschrijving</h3>
            <form onSubmit={handleSubmit}>
                <label>Bedrijfsnaam ***<input type="text" name="companyName" value={formData.companyName} onChange={handleChange} required /></label>
                <label>Omschrijving<textarea name="description" value={formData.description} onChange={handleChange} /></label>
                <br />
                <h3>Bedrijfsgegevens</h3>
                <label>Straatnaam<input type="text" name="streetName" value={formData.streetName} onChange={handleChange} /></label>
                <label>Huisnummer<input type="text" name="houseNumber" value={formData.houseNumber} onChange={handleChange} /></label>
                <label>Postcode<input type="text" name="zipcode" value={formData.zipcode} onChange={handleChange} /></label>
                <label>Plaats<input type="text" name="city" value={formData.city} onChange={handleChange} /></label>
                <label>Telefoonnummer<input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} /></label>
                <label>Website<input type="url" name="website" value={formData.website} onChange={handleChange} /></label>
                <label>E-Mail<input type="email" name="email" value={formData.email} onChange={handleChange} /></label>
                <br/>
                <h3>Selecteer uw werkgebied</h3>
                <div className="active-area-container">
                    <div className="align-row"><input type="checkbox" name="province" value="Groningen" onChange={handleChange} /><span>Groningen</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Friesland" onChange={handleChange} /><span>Friesland</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Drenthe" onChange={handleChange} /><span>Drenthe</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Overijssel" onChange={handleChange} /><span>Overijssel</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Flevoland" onChange={handleChange} /><span>Flevoland</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Gelderland" onChange={handleChange} /><span>Gelderland</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Utrecht" onChange={handleChange} /><span>Utrecht</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Noord-Holland" onChange={handleChange} /><span>Noord-Holland</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Zuid-Holland" onChange={handleChange} /><span>Zuid-Holland</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Zeeland" onChange={handleChange} /><span>Zeeland</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Noord-Brabant" onChange={handleChange} /><span>Noord-Brabant</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Limburg" onChange={handleChange} /><span>Limburg</span></div>
                    <div className="align-row"><input type="checkbox" name="province" value="Nederland" onChange={handleChange} /><span>Heel Nederland</span></div>
                </div>
{/*
                <br/>

                <h3>Selecteer een categorie</h3>
                <div className="active-area-container">
                    <div className="align-row"><input type="checkbox" name="category" value="BouwRenovatie" onChange={handleChangeCategory} /><span>Bouw & Renovatie</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="Installatie" onChange={handleChangeCategory} /><span>Installatie</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="IT" onChange={handleChangeCategory} /><span>IT</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="Recruitment" onChange={handleChangeCategory} /><span>Recruitment</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="Marketing" onChange={handleChangeCategory} /><span>Marketing</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="Producten" onChange={handleChangeCategory} /><span>Producten</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="Machines" onChange={handleChangeCategory} /><span>Machines</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="Zorg" onChange={handleChangeCategory} /><span>Zorg</span></div>
                    <div className="align-row"><input type="checkbox" name="category" value="Logistiek" onChange={handleChangeCategory} /><span>Logistiek</span></div>
                </div>

*/}
                        <br/>
                <h3>Socials (Optioneel)</h3>
                <label>Facebook url (incl. https://)<input type="url" name="facebookUrl" value={formData.facebookUrl} onChange={handleChange} /></label>
                <label>LinkedIn url (incl. https://)<input type="url" name="linkedInUrl" value={formData.linkedInUrl} onChange={handleChange} /></label>
                <label>Instagram url (incl. https://)<input type="url" name="instagramUrl" value={formData.instagramUrl} onChange={handleChange} /></label>
                <label>Tiktok url (incl. https://)<input type="url" name="tiktokUrl" value={formData.tiktokUrl} onChange={handleChange} /></label>
                <label>Youtube url (incl. https://)<input type="url" name="youtubeUrl" value={formData.youtubeUrl} onChange={handleChange} /></label>
                <label>X url (incl. https://)<input type="url" name="xUrl" value={formData.xUrl} onChange={handleChange} /></label>
                <br/>
{/*
                <h3>Voor website.nl</h3>
                <label>Contactpersoon<input type="text" name="contactPerson" value={formData.contactPerson} onChange={handleChange} /></label>
*/}
                <button type="submit">Aanmelden</button>
                <br/>
                <br/>
            </form>
            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />
            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />
        </div>
    );
};

export default CreateBusinessProfile;
