import React, { useState } from 'react';

const PrimaryCta = (props) => {
    const primaryCtaStyle = {
        backgroundColor: '#ff6600',
        color: '#ffffff',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '4px',
        fontSize: '16px',
        cursor: 'pointer',
        marginRight: '10px',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

};

    return (
        <div>
            <a href={props.primaryLink}>
                <button
                    style={primaryCtaStyle}
                >
                    {props.primaryText || 'Aanmelden'}
                </button>
            </a>

        </div>
    );
};

export default PrimaryCta;
