import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AdminPanel.css';
import BackToMyProfile from "../user/profile/buttons/BackToMyProfile"; // Import the corresponding CSS file

function AdminPanel() {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleRedirectToSolarpanelAdmin = () => {
        navigate('/admin-panel/solarpanels'); // Redirect to SolarpanelAdmin
    };
    const handleRedirectToPriceIndicationAdmin = () => {
        navigate('/admin-panel/price-indication'); // Redirect to PriceIndication
    };

    const handleRedirectToCombiDash = () => {
        navigate('/admin-panel/combi-dashboard'); // Redirect to User, request and appointment dashboard
    };
    const handleRedirectToValidateBp = () => {
        navigate('/admin-panel/validate-bp'); // Redirect to User, request and appointment dashboard
    };
    const handleRedirectToUpdateBp = () => {
        navigate('/admin-panel/update-bp'); // Redirect to User, request and appointment dashboard
    };

    const handleRedirectToDeleteBp = () => {
        navigate('/admin-panel/delete-bp'); // Redirect to User, request and appointment dashboard
    };

    return (
        <div className="admin-panel-container">
            <h2 className="admin-header">Admin Panel</h2>
            <div className="admin-buttons-container">
                <button className="admin-button" onClick={handleRedirectToSolarpanelAdmin}>Solarpanels</button>
                <button className="admin-button" onClick={handleRedirectToPriceIndicationAdmin}>Price indication</button>
                <button className="admin-button-combi-dash" onClick={handleRedirectToCombiDash}>Web Traffic</button>
                <label htmlFor="validateBp" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Validate/update + enable/disable business profiles:
                </label>
                <button
                    style={{
                        backgroundColor: '#3498db',
                        color: 'white',
                        padding: '8px 12px',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                    onClick={handleRedirectToValidateBp}
                >
                    Validate Bp
                </button>

                <label htmlFor="deleteBp" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Delete business profiles:
                </label>
                <button
                    style={{
                        backgroundColor: '#e74c3c',
                        color: 'white',
                        padding: '8px 12px',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                    onClick={handleRedirectToDeleteBp}
                >
                    Delete Bp
                </button>

            </div>
            <br/>
            <br/>
            <br/>
            <BackToMyProfile/>
        </div>
    );
}

export default AdminPanel;

{/*
    const handleRedirectToUsersAdmin = () => {
        navigate('/admin-panel/users'); // Redirect to UsersAdmin
    };
    const handleRedirectToRequests = () => {
        navigate('/admin-panel/requests'); // Redirect to UsersAdmin
    };
    const handleRedirectToAppointments = () => {
        navigate('/admin-panel/appointments'); // Redirect to UsersAdmin
    };
            <button className="admin-button" onClick={handleRedirectToUsersAdmin}>Gebruikers</button>
            <button className="admin-button" onClick={handleRedirectToRequests}>Aanvragen</button>
            <button className="admin-button" onClick={handleRedirectToAppointments}>Afspraken</button>
*/}
