import React, {useEffect, useRef} from 'react';
import '../../../../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { useNavigate } from "react-router-dom";
import Request from "../../user/profile/request/Request";

function HeroSection() {

    const navigator = useNavigate();
    const PageTitle = useRef();
    const Buttons = useRef();
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const offset = 100; // Adjust this value as needed
            const elementPosition = element.offsetTop - offset;
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleLinkClick = (id) => {
        scrollTo(id); // Scroll to the corresponding section
    };



    return (
        <div className='hero-container' id="hero-section">

{/*
            <h1 className="hero-page-title" ref={PageTitle}><span className="direct-hero-section" >Zonnemacht</span></h1>
*/}
            <div className="hc-container">
                <br/>
                <br/>

                <Request />

            </div>
        </div>


    );
}

export default HeroSection;
{/*

            <div className="hero-container-card-price-indication">
                <CardItemPriceIndication
                    src={PriceIndication}
                    text='Calculate your price indication'
                    label='Price indication'
                    path='/request-container'
                />
            </div>
            <div className="hero-container-card-the-next-step">
                <CardItemTheNextStep
                    src={Statisfied}
                    text='Schedule the next step'
                    label='The next step'
                    path='/contact'
                />
            </div>

*/}
