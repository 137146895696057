import React from 'react';
import './AdminCombiDashboard.css';
import AppointmentsAdmin from "../appointments/AppointmentsAdmin";
import RequestsAdmin from "../requests/RequestsAdmin";
import UsersAdmin from "../users/UsersAdmin";
import ValidateBp from "./ValidateBp";
import PriceQuoteAdmin from "../../user/profile/request/components/PriceQuoteAdmin";



function AdminCombiDashboard() {
    return (
        <div className="admin-combi-dashboard-container">
            <div className="admin-combi-dashboard-flex-container">
                <div className="admin-combi-dashboard-body-top-container">
{/*

                    <UsersAdmin />

                    <ValidateBp />
*/}
                </div>

                <div className="admin-combi-dashboard-body-container">
                    <div className="admin-combi-dashboard-body-start-container">
{/*
                    <AppointmentsAdmin />
*/}
                        <PriceQuoteAdmin />

                    </div>
                    <div className="admin-combi-dashboard-body-end-container">
                    <RequestsAdmin />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminCombiDashboard;
