import React, {useContext} from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import Homepage from './components/pages/public/homepage/Homepage';
import About from './components/pages/public/about/About';
import Contact from './components/pages/public/contact/Contact';
import Login from './components/Login';
import AdminPanel from './components/pages/admin/AdminPanel';
import Profile from "./components/pages/user/profile/Profile";
import ProfileSettings from "./components/pages/user/settings/ProfileSettings";
import ProfileRequests from "./components/pages/user/profile/request/ProfileRequests";
import RegisterPage from "./components/pages/public/register/RegisterPage";
import Terms from "./components/pages/public/legalties/Terms";
import ProfileAppointments from "./components/pages/user/profile/appointment/ProfileAppointments";
import Header from './components/pages/public/Header';
import Footer from './components/pages/public/Footer';
import Request from "./components/pages/user/profile/request/Request";
import SolarpanelAdmin from "./components/pages/admin/settings/SolarpanelAdmin";
import PriceIndicationAdmin from "./components/pages/admin/settings/PriceIndicationAdmin";
import UsersAdmin from "./components/pages/admin/users/UsersAdmin";
import RequestsAdmin from "./components/pages/admin/requests/RequestsAdmin";
import AppointmentsAdmin from "./components/pages/admin/appointments/AppointmentsAdmin";
import ScheduleAppointment from "./components/pages/user/profile/appointment/ScheduleAppointment";
import AdminCombiDashboard from "./components/pages/admin/dashboard/AdminCombiDashboard";
import CookieConsent from "./context/CookieConsent";
import PrivacyPolicy from "./components/pages/public/legalties/PrivacyPolicy";
import Disclaimer from "./components/pages/public/legalties/Disclaimer";
import Information from "./components/pages/public/information/Information";
import Toolkit from "./components/pages/public/toolkit/Toolkit";
import InstallationPriceCalculator from "./components/pages/public/toolkit/tools/InstallationPriceCalculator";
import InfoInstallationPriceCalculator from "./components/pages/public/information/articles/InfoInstallationPriceCalculator";
import Welcome from "./components/pages/public/funnels/main/Welcome";
import InfoSolarEnergyBilling from "./components/pages/public/information/articles/InfoSolarEnergyBilling";
import RegisterRequest from "./components/pages/public/register/components/RegisterRequest";
import Demo from "./components/pages/public/demo/Demo";
import PayBackPeriodCalculator from "./components/pages/public/toolkit/tools/PayBackPeriodCalculator";
import PayBackPeriod from "./components/pages/user/profile/paybackperiod/PayBackPeriod";
import PayBackPeriodResults from "./components/pages/user/profile/paybackperiod/components/PayBackPeriodResults";
import DemoPayBackPeriod from "./components/pages/public/demo/components/DemoPayBackPeriod";
import DemoRequest from "./components/pages/public/demo/components/DemoRequest";
import DemoPayBackPeriodResult from "./components/pages/public/demo/components/DemoPayBackPeriodResult";
import DemoRequestResult from "./components/pages/public/demo/components/DemoRequestResult";
import WelcomeToZonnemacht from "./components/pages/public/information/articles/WelcomeToZonnemacht";
import QuantityType from "./components/pages/public/funnels/main/QuantityType";
import MainResult from "./components/pages/public/funnels/main/MainResult";
import PickPanel from "./components/pages/public/funnels/main/PickPanel";
import PostPickPanel from "./components/pages/public/funnels/main/PostPickPanel";
import CalculatorAndAppointment from "./components/pages/public/information/articles/CalculatorAndAppointment";
import SolarpanelCarousel from "./components/pages/user/profile/request/components/SolarpanelCarousel";
import PriceIndicationTable from "./components/pages/user/profile/request/components/PriceIndicationTable";
import PriceQuote from "./components/pages/user/profile/request/components/PriceQuote";
import PqApprovalPage from "./components/pages/user/profile/request/components/PqApprovalPage";
import SolarpanelsOffer from "./components/pages/user/profile/request/components/SolarpanelsOffer";
/*
import ValidateBp from "./components/pages/admin/dashboard/ValidateBp";
import UpdateBp from "./components/pages/admin/dashboard/UpdateBp";
*/
import CreateBusinessProfile from "./components/pages/public/homepage/CreateBusinessProfile";
import BpSuccessfulPage from "./components/pages/public/homepage/BpSuccessfulPage";
import InstallationOffer from "./components/pages/public/homepage/InstallationOffer";
import ValidateBp from "./components/pages/user/profile/request/components/ValidateBp";
import UpdateBp from "./components/pages/user/profile/request/components/UpdateBp";
import DeleteBp from "./components/pages/user/profile/request/components/DeleteBp";
import PriceQuoteAdmin from "./components/pages/user/profile/request/components/PriceQuoteAdmin";

function App() {
    const { isAuth } = useContext(AuthContext);

    return (
        <div className="App">
            <CookieConsent />
            <Header />
            <Routes>
                {/* Open routes */}
                <Route path="/" element={<Homepage />} />
                <Route path="/create/businessprofile" element={ <CreateBusinessProfile /> } />
                <Route path="/create/businessprofile/successful" element={ <BpSuccessfulPage /> } />
                <Route path="/installation-offer" element={ <InstallationOffer /> } />

{/*
                <Route path="/solarpanel-carousel" element={<SolarpanelCarousel />} />
*/}
                <Route path="/request" element={ <Request /> } />
                <Route path="/price-indication" element={<PriceIndicationTable />} />
                <Route path="/price-quote" element={<PriceQuote />} />
                <Route path="/pq-approval-page" element={<PqApprovalPage />} />


                {/*
                <Route path="/solarpanels-offer" element={ <SolarpanelsOffer /> } />
*/}



                <Route path="/login" element={<Login />} />


                <Route path="/about" element={<About />} />


                <Route path="/contact" element={<Contact />} />







                <Route path="/information" element={<Information />} />
{/*
                <Route path="/information/installation-price-calculator" element={<InfoInstallationPriceCalculator />} />
*/}
                <Route path="/information/solar-energy-billing" element={<InfoSolarEnergyBilling />} />
{/*
                <Route path="/information/welcome-to-zonnemacht" element={<WelcomeToZonnemacht />} />
                <Route path="/information/calculator-and-appointment" element={<CalculatorAndAppointment />} />
*/}




                {/*Open catch-register routes*/}

{/*
                <Route path="/register-page" element={<RegisterPage />} />
*/}


                {/*Open legalities routes*/}
                <Route path="/terms-of-agreement" element={<Terms />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />


                {/* Protected routes */}
                {/* User */}
{/*
                <Route path="/schedule-appointment" element={isAuth ? <ScheduleAppointment /> : <Navigate to="/login" />} />
*/}
{/*
                <Route path="/profile" element={isAuth ? <Profile /> : <Navigate to="/login" />} />
*/}
{/*
                <Route path="/profile/settings" element={isAuth ? <ProfileSettings /> : <Navigate to="/login" />}/>
                <Route path="/profile/requests" element={isAuth ? <ProfileRequests /> : <Navigate to="/login" />}/>
                <Route path="/profile/appointments" element={isAuth ? <ProfileAppointments /> : <Navigate to="/login" />}/>
*/}

                {/* Admin */}
                <Route path="/admin-panel" element={isAuth ? <AdminPanel /> : <Navigate to="/login" />}/>
                <Route path="/admin-panel/solarpanels" element={isAuth ? <SolarpanelAdmin /> : <Navigate to="/login" />}/>
                <Route path="/admin-panel/price-indication" element={isAuth ? <PriceIndicationAdmin /> : <Navigate to="/login" />}/>
{/*
                <Route path="/admin-panel/users" element={isAuth ? <UsersAdmin /> : <Navigate to="/login" />}/>
*/}
                <Route path="/admin-panel/requests" element={isAuth ? <RequestsAdmin /> : <Navigate to="/login" />}/>
                <Route path="/admin-panel/pricequotes" element={isAuth ? <PriceQuoteAdmin /> : <Navigate to="/login" />}/>

                {/*
                <Route path="/admin-panel/appointments" element={isAuth ? <AppointmentsAdmin /> : <Navigate to="/login" />}/>
*/}
                <Route path="/admin-panel/combi-dashboard" element={isAuth ? <AdminCombiDashboard /> : <Navigate to="/login" />}/>
                <Route path="/admin-panel/validate-bp" element={isAuth ? <ValidateBp /> : <Navigate to="/login" />}/>
                <Route path="/admin-panel/update-bp" element={isAuth ? <UpdateBp /> : <Navigate to="/login" />}/>
                <Route path="/admin-panel/delete-bp" element={isAuth ? <DeleteBp /> : <Navigate to="/login" />}/>

            </Routes>

            <Footer />
        </div>
    );
}


export default App;

{/*
                    <Route path="/request-container" element={<RequestContainer />} />
*/}
{/*
                <ScheduleAppointment/>
*/}
//                     <Route path="/forgot-password" element={<ForgotPassword />} />
//                     <Route path="/adminpanel" element={<AdminPanel />} />
//                     <Route path="/adminpanel/solarpanel" element={<SolarpanelAdmin />} />
//                     <Route path="/adminpanel/price-indication" element={<PriceIndicationAdmin />} />
//                     <Route path="/adminpanel/users" element={<UsersAdmin />} />



{/*<Route path="/appointment-succes-page" element={isAuth ? <AppointmentSuccessPage /> : <Navigate to="/login" />} />// I think this path is not used anymore*/}



// I like this color #ff7700