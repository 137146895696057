// Welcome.js

import React from 'react';
import './Welcome.css';
import { useNavigate } from 'react-router-dom';
import PrimaryCta from '../../../../cta/PrimaryCta';
import SecondaryCtaInput from '../../../../cta/SecondaryCtaInput';
import { BsCheckSquareFill } from 'react-icons/bs';
import {AiOutlineFieldTime} from "react-icons/ai";

function Welcome() {
    const isSubmitDisabled = true; // Change this value as needed
    const navigate = useNavigate(); // Initialize navigate from React Router

    const handleCustomSubmit = () => {
        navigate('/quantity-and-type');
    };

    return (
        <div className="welcome-container">
            <h1 className="welcome-title">
                <a href="/register-page">Meld je aan</a> voor resultaten op maat.
            </h1>
            <PrimaryCta primaryLink="/register-page" />
            <h2>Wil je het eerst uitproberen? </h2>
            <p className="welcome-p"></p>

            <SecondaryCtaInput
                secondaryText="Ga verder als gast"
                onSubmit={handleCustomSubmit}
            />

            <div className="account-benefits">
                <p>
                    Met een Zonnemacht account genereer je in {' '}
                    <span className="highlighted-text">
          <AiOutlineFieldTime />60 seconden
        </span>{' '}
                    een compleet
                    rapport met o.a. :
                </p>
                <ul>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Afbouw salderingsregeling
                    </li>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Direct eigen verbruik
                    </li>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Terugverdientijd
                    </li>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Jaarlijkse opbrengsten
                    </li>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Jaarlijkse kosten
                    </li>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Terugleverkosten
                    </li>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Zonnepanelen vergelijker
                    </li>
                    <li>
                        <BsCheckSquareFill className="icon-style" />
                        Installatieprijs : 100% Transparant
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Welcome;

