import React from 'react';
import ShareButton from "../../buttons/ShareButton";
import './Article.css';
import {useNavigate} from 'react-router-dom';

export function ArticleCard({Link,ImageUrl, Header, Description}) {

    const navigate = useNavigate();

    return (
        <div onClick={()=>{navigate(Link);} } className="article-card-container">
            <img src={ImageUrl} alt={''}/>
            <div>
                <h2><strong>{Header}</strong></h2>
                <p>{Description}</p>
            </div>
        </div>
    );
}


export function Image({ImageUrl, Alt}) {
    return (
        <div className="article-image">
            <img src={ImageUrl} alt={Alt}/>
        </div>
    );
}

export function Header({Header,size}) {

    switch (size){
        case "smaller": size = 0.8; break;
        case "small": size = 1; break;
        case "medium": size = 1.3; break;
        case "big" : size = 1.5; break;
        case "bigger" : size = 1.8; break;
        default: size = 0.8;
    }
    return ( <h1 style={{fontSize: `${size}rem`}}>{Header}</h1> );
}

export const Paragraph = ({ children, BigSpace }) => {
    return (
        <div className={`article-paragraph-container ${BigSpace ? 'paragraph-bigSpace' : ''}`}>
            {children}
            <br/>
        </div>
    );
};

// Only content with no header
export function List(listArray) {
    return (
        <div className="article-list-container">
        </div>
    );
}

export function Share() {
    return ( <ShareButton /> );
}

// The input must be an array of items which have a header and content
export function NumberedListWithHeader({ listArrayWithHeader }) {
    return (
        <div className="article-list-container">
            {listArrayWithHeader.map((item, index) => (
                <div key={index}>
                    <p><strong>{index + 1}. {item.header}</strong> {item.content}</p>
                </div>
            ))}
        </div>
    );
}

export function ListWithHeader({ listArrayWithHeader }) {
    return (
        <div className="article-list-container">
            {listArrayWithHeader.map((item, index) => (
                <div key={index}>
                    <p><strong>{item.header}</strong> {item.content}</p>
                </div>
            ))}
        </div>
    );
}