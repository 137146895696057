import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PriceIndicationTable.css';
import PrimaryCta from "../../../../../cta/PrimaryCta";
import SecondaryCtaNoInput from "../../../../../cta/SecondaryCtaNoInput";
import CompanyAdvertisement from "../../../../public/homepage/CompanyAdvertisement";
import Zonnemacht from "../../../../../../assets/Zonnemacht.png"; // Import the CSS file

const PriceIndicationTable = () => {
    const [request, setRequest] = useState(null); // Store the fetched request data

    useEffect(() => {
        const lastRequestUuid = localStorage.getItem('lastRequestUuid');

        if (lastRequestUuid) {
/*
            const token = localStorage.getItem('token'); // Get the authentication token
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
*/

            axios
                .get(`${process.env.REACT_APP_API_HOST}/request/${lastRequestUuid}`)
                .then((response) => {
                    const fetchedRequest = response.data;
                    setRequest(fetchedRequest);
                })
                .catch((error) => {
                    console.error('Error fetching last request:', error);
                });
        } else {
            console.error('No lastRequestUuid found in localStorage.');
        }
    }, []); // Fetch data when the component mounts

    // Conditionally render content based on the fetched request
    if (!request) {
        return <p>Loading...</p>;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year}, ${hours}:${minutes}`;
    }

    return (
        <div className="price-indication-container">
            <h2 className="price-indication-header">Zie-zo, Uw prijsindicatie !</h2>
            <p>Datum aangemaakt: <strong>{formatDate(request.createdAt)}</strong></p>
            <p>Prijsindicatie totaal: <strong>{request.resultTotalCost ? `€${request.resultTotalCost}` : 'N.V.T.'}</strong></p>
            <PrimaryCta
                primaryText="Gratis offerte-aanvraag via Zonnemacht.nl"
                primaryLink="/price-quote"
            />
            <br/>
{/*
            <SecondaryCtaNoInput
                primaryLink="/installation-offer"
                primaryText="Bekijk aanbod installateurs.."
            />
*/}
            <table className="price-indication-table">
                <thead>
                <tr>
                    <th>Product / Dienst</th>
                    <th>Aantal</th>
                    <th>Prijs</th>
                    <th>Totaal excl</th>
                </tr>
            </thead>
                <tbody>
                        <tr>
                            <td>{request.resultSolarPanelName ? request.resultSolarPanelName : 'N.V.T.'}</td>
                            <td>{request.resultSolarPanelQuantity ? request.resultSolarPanelQuantity : 'N.V.T.'}</td>
                            <td>{request.resultSolarPanelCost ? `€${request.resultSolarPanelCost}` : 'N.V.T.'}</td>
                            <td>{request.resultSolarPanelTotalCost ? `€${request.resultSolarPanelTotalCost}` : 'N.V.T.'}</td>
                        </tr>
                        <tr>
                            <td>Platdak montage materiaal</td>
                            <td>{request.flatRoofPanels || 0}</td>
                            <td>{request.resultFlatRoofAssemblyMaterialCost ? `€${request.resultFlatRoofAssemblyMaterialCost}` : 'N.V.T.'}</td>
                            <td>{request.resultFlatRoofAssemblyMaterialTotalCost ? `€${request.resultFlatRoofAssemblyMaterialTotalCost}` : 'N.V.T.'}</td>
                        </tr>

                        <tr>
                            <td>Schuindak montage materiaal</td>
                            <td>{request.slantedRoofPanels || 0}</td>
                            <td>{request.resultSlantedRoofAssemblyMaterialCost ? `€${request.resultSlantedRoofAssemblyMaterialCost}` : 'N.V.T.'}</td>
                            <td>{request.resultSlantedRoofAssemblyMaterialTotalCost ? `€${request.resultSlantedRoofAssemblyMaterialTotalCost}` : 'N.V.T.'}</td>
                        </tr>

                        <tr>
                            <td>Omvorming</td>
                            <td>{request.resultInversionQuantity || 0}</td>
                            <td>{request.resultInversionCost ? `€${request.resultInversionCost}` : 'N.V.T.'}</td>
                            <td>{request.resultInversionTotalCost ? `€${request.resultInversionTotalCost}` : 'N.V.T.'}</td>
                        </tr>

                        <tr>
                            <td>Verankering</td>
                            <td>{request.resultAnchoringQuantity || 0}</td>
                            <td>{request.resultAnchoringCost ? `€${request.resultAnchoringCost}` : 'N.V.T.'}</td>
                            <td>{request.resultAnchoringTotalCost ? `€${request.resultAnchoringTotalCost}` : 'N.V.T.'}</td>
                        </tr>

                        <tr>
                            <td>Elektra</td>
                            <td>{request.resultElectricsQuantity || 0}</td>
                            <td>{request.resultElectricsCost ? `€${request.resultElectricsCost}` : 'N.V.T.'}</td>
                            <td>{request.resultElectricsTotalCost ? `€${request.resultElectricsTotalCost}` : 'N.V.T.'}</td>
                        </tr>

                        <tr>
                            <td>Levering</td>
                            <td>{request.resultDeliveryCostQuantity || 0}</td>
                            <td>{request.resultDeliveryCostCost ? `€${request.resultDeliveryCostCost}` : 'N.V.T.'}</td>
                            <td>{request.resultDeliveryCostCost ? `€${request.resultDeliveryCostCost}` : 'N.V.T.'}</td>
                        </tr>

                        <tr>
                            <td>Hoogte Transport</td>
                            <td>{request.resultHeightTransportQuantity || 0}</td>
                            <td>{request.resultHeightTransportCost ? `€${request.resultHeightTransportCost}` : 'N.V.T.'}</td>
                            <td>{request.resultHeightTransportCost ? `€${request.resultHeightTransportCost}` : 'N.V.T.'}</td>
                        </tr>

                        <tr>
                            <td>Installatie Team</td>
                            <td>{request.resultInstallationTeamQuantity || 0}</td>
                            <td>{request.resultInstallationTeamCost ? `€${request.resultInstallationTeamCost}` : 'N.V.T.'}</td>
                            <td>{request.resultInstallationTeamCost ? `€${request.resultInstallationTeamCost}` : 'N.V.T.'}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Totaal prijs</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td style={{fontWeight: 'bold'}}>{request.resultTotalCost ? `€${request.resultTotalCost}` : 'N.V.T.'}</td>
                        </tr>

                </tbody>

            </table>
            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />

        </div>
    );
};

export default PriceIndicationTable;


//TODO is this inactive?