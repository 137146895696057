import React from 'react';
import './CardItemContactPage.css';
import { Link } from 'react-router-dom';

const CardItemContactPage = ({ src, label, text, path }) => {
    return (
        <Link className="contact-card" to={path}>
            <img src={src} alt={label} className="contact-card-image" />
            <h3 className="contact-card-label-contact-page">{label}</h3>
            <p className="contact-card-text">{text}</p>
        </Link>
    );
};

export default CardItemContactPage;
