import React, { useState } from 'react';
import './PayBackPeriod.css';
import axios from "axios";
import {useNavigate} from "react-router-dom";
const API_BASE_URL = `${process.env.REACT_APP_API_HOST}`;

function PayBackPeriodCalculator() {

    const [formData, setFormData] = useState({
        usedEnergy: 5000,
        generatedEnergy: 7000,
        electricityRate: 0.40,
        directConsumptionRate: 33,
        installationPrice: 10000,
        cappedRetourFee: 0.12,
    });

    const navigate = useNavigate(); // Hook for programmatic navigation


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSend = async () => {
        const token = localStorage.getItem('token');

        try {
            const headers = {
                'Authorization': `Bearer ${token}`, // Include the JWT token here
                'Content-Type': 'application/json', // Modify based on your API's requirements
            };

            const response = await axios.post(`${API_BASE_URL}/paybackperiod/create`, formData, { headers });

            // Clear form and navigate to a new page
            setFormData({
                usedEnergy: 0,
                generatedEnergy: 0,
                electricityRate: 0.0,
                directConsumptionRate: 0,
                installationPrice: 0,
                cappedRetourFee: 0.0,
            });
            navigate('/request/paybackperiod/results');
        } catch (error) {
            if (error.response) {
                // Handle specific error cases if needed
            }
            alert('Kies een zonnepaneel en vul een veld in.');
        }
    };

    return (
        <div className="paybackperiod-container">
            <div className="input-field">
                <label htmlFor="usedEnergy">Used Energy</label>
                <input
                    type="number"
                    id="usedEnergy"
                    name="usedEnergy"
                    value={formData.usedEnergy}
                    onChange={handleInputChange}
                />
            </div>

            <div className="input-field">
                <label htmlFor="generatedEnergy">Generated Energy</label>
                <input
                    type="number"
                    id="generatedEnergy"
                    name="generatedEnergy"
                    value={formData.generatedEnergy}
                    onChange={handleInputChange}
                />
            </div>

            <div className="input-field">
                <label htmlFor="electricityRate">Electricity Rate</label>
                <input
                    type="number"
                    step="0.01"
                    id="electricityRate"
                    name="electricityRate"
                    value={formData.electricityRate}
                    onChange={handleInputChange}
                />
            </div>

            <div className="input-field">
                <label htmlFor="directConsumptionRate">Direct Consumption Rate</label>
                <input
                    type="number"
                    id="directConsumptionRate"
                    name="directConsumptionRate"
                    value={formData.directConsumptionRate}
                    onChange={handleInputChange}
                />
            </div>

            <div className="input-field">
                <label htmlFor="installationPrice">Installation Price</label>
                <input
                    type="number"
                    id="installationPrice"
                    name="installationPrice"
                    value={formData.installationPrice}
                    onChange={handleInputChange}
                />
            </div>

            <div className="input-field">
                <label htmlFor="cappedRetourFee">Capped Retour Fee</label>
                <input
                    type="number"
                    step="0.01"
                    id="cappedRetourFee"
                    name="cappedRetourFee"
                    value={formData.cappedRetourFee}
                    onChange={handleInputChange}
                />
            </div>
            <div className="button-container">
                <button onClick={handleSend}>Direct Resultaat</button>
            </div>
        </div>
    );
}

export default PayBackPeriodCalculator;
