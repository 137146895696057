import React from 'react';
import './PqApprovalPage.css';
import {useNavigate} from "react-router-dom"; // Import your CSS styles if needed

const PqApprovalPage = () => {
/*
    const navigate = useNavigate();

    const handleLearnMoreClick = () => {
        // Redirect to the /information route
        navigate('/information');
    };
*/


    return (
        <div className="pq-approval-page-container">
            <h2 className="pq-approval-page-header">Gelukt!</h2>
            <p className="pq-approval-page-message">
                Je hebt geheel vrijblijvend een offerte aangevraagd.
            </p>
{/*            <button className="learn-more-button" onClick={handleLearnMoreClick}>
                Lees meer over zonne-energie...
            </button>*/}
        </div>
    );
};

export default PqApprovalPage;
