import React from 'react';
import './Disclaimer.css';

function Disclaimer() {
    return (
        <div className="disclaimer-container">
            <h1>Disclaimer</h1>
            <p>
                De informatie verstrekt op onze dienst is uitsluitend bedoeld voor algemene informatiedoeleinden. Hoewel we streven naar het verstrekken van nauwkeurige en actuele informatie, kunnen we niet garanderen dat de informatie op onze dienst te allen tijde volledig, accuraat en up-to-date is. Wij zijn niet verantwoordelijk voor enige acties die u onderneemt op basis van de informatie op onze dienst.
            </p>

            <p>Zonnemacht doet haar uiterste best om te voorkomen dat de informatie die op onze website wordt verstrekt fouten bevat of in-accuraat is. Echter kunnen we deze garantie helaas niet geven. Voor eventuele onjuistheden op onze website is Zonnemacht niet aansprakelijk. Wij stellen het op prijs als u ons op de hoogte brengt van foutieve informatie op onze website als u dat ergens heeft gezien.

                Alles wat op de website van Zonnemacht staat mag enkel gebruikt worden voor niet-commerciële doeleinden. Bezoekers en gebruikers van de website van Zonnemacht mogen zonder uitdrukkelijke schriftelijke toestemming van Zonnemacht niks van de website overnemen, vermenigvuldigen, verspreiden of op een andere manier ter beschikking stellen aan anderen. Zonnemacht is de rechthebbende en behoudt alle rechten, waaronder auteurs- en merkrechten en/of andere intellectueel eigendomsrechten, met betrekking tot alles wat op de website van Zonnemacht staat.
            </p>
        </div>
    );
}

export default Disclaimer;




//TODO Re-format text