import React from 'react';
import './Terms.css';

function Terms() {
    return (
        <div className="terms-container">
            <h1>Algemene Voorwaarden</h1>
            <h2>Onze werkwijze</h2>
            <p>Welkom bij onze dienst! </p>
            <p>Zonnemacht maakt software om zonne-energie toegankelijker te maken. Dit doen we door je kort te informeren over verschillende zonnepanelen segmenten en vervolgens voorzien wij je ook van een prijsindicatie.</p>
            <p>De op deze website getoonde informatie wordt door Zonnemacht met zorg samengesteld, doch voor de juistheid en volledigheid daarvan kan niet worden ingestaan.</p>
            <p>Zonnemacht deelt uw voor- en achternaam, contactgegevens (email en telefoonnummer), adres (postcode, huisnummer), gemaakte afspraken (telefonisch en op locatie) en prijs indicatie resultaten met 1 of meerdere installatie bedrijven die actief zijn bij jou in de buurt. Deze installatie bedrijven betalen Zonnemacht voor de gedeelde informatie. Op deze manier kunnen de installatie bedrijven sneller nieuwe klanten vinden.</p>
            <p>Via Zonnemacht kom je vrijblijvend in contact met 1 of meerdere installatie bedrijven die actief zijn bij jou in de buurt. </p>
            <p>Wij zijn dus verder niet betrokken bij de eventuele overeenkomst die je sluit met installatiebedrijven. Dit gaat compleet buiten het platform van Zonnemacht om. Je bent uiteraard niet verplicht om te kiezen voor het installatie-bedrijf waar wij jouw gegevens aan verstrekken. Jij kiest helemaal zelf door wie jij een eventuele installatie laat verrichten en onder welke voorwaarden. Heb je klachten over het eindresultaat of over het installatie-bedrijf waar je voor gekozen hebt? Of heb je schade geleden? Het installatie-bedrijf is verantwoordelijk voor de juiste uitvoering van de werkzaamheden die je bent overeengekomen. Je dient dan ook het installatie-bedrijf aan te spreken op de kwaliteit van de werkzaamheden. Als het niet lukt om met het installatiebedrijf tot een oplossing te komen, vernemen wij dit graag van je. Neem dan contact op met Zonnemacht via contact@zonnemacht.com. Zonnemacht neemt iedere klacht zeer serieus. Bij twijfel over een installatie-bedrijf starten we een onderzoek en nemen we de gepaste maatregelen. Indien nodig beëindigen we de samenwerking met het installatie-bedrijf in kwestie. </p>
            <p>Deze Algemene Voorwaarden vormen een juridische overeenkomst tussen u en <strong>Zonnemacht ("wij", "ons", "onze", of "het Bedrijf")</strong> en bevatten belangrijke informatie over uw rechten en plichten met betrekking tot het gebruik van onze dienst.</p>

            <h2>1. Acceptatie van de Voorwaarden</h2>

            <p>
                Door gebruik te maken van onze dienst, gaat u akkoord met deze Algemene Voorwaarden en gaat u ermee akkoord zich te houden aan de hierin opgenomen bepalingen. Als u niet akkoord gaat met deze Voorwaarden, dient u geen gebruik te maken van onze dienst.
            </p>

            <h2>2. Verzamelen en Delen van Persoonlijke Gegevens</h2>

            <p>
                Wij verzamelen persoonlijke gegevens waaronder voor- en achternaam, contactgegevens (email en telefoonnummer), adres (postcode, huisnummer), gemaakte afspraken (telefonisch en op locatie) en prijs indicatie resultaten met als doel het mogelijk maken van de communicatie tussen u en installatie-bedrijven. U stemt ermee in dat wij deze gegevens kunnen delen met installatiebedrijven om u te voorzien van informatie en advies over zonne-energie installaties en om mogelijk een overeenkomst te sluiten.
            </p>

            <h2>3. Uw Verplichtingen</h2>

            <p>
                U stemt ermee in dat de verstrekte informatie accuraat is en dat u de vereiste toestemming hebt om deze informatie met ons te delen. U zult onze dienst niet gebruiken voor illegale doeleinden of in strijd met toepasselijke wet- en regelgeving.
            </p>

            <h2>4. Beëindiging</h2>

            <p>
                Wij behouden het recht om uw toegang tot onze dienst op elk moment en om welke reden dan ook te beëindigen of op te schorten zonder voorafgaande kennisgeving.
            </p>

            <h2>5. Contact en Communicatie</h2>

            <p>
                Door akkoord te gaan met deze algemene voorwaarden gaat u ermee akkoord dat wij of installatiebedrijven waarmee wij uw gegevens ( voor- en achternaam, contactgegevens (email en telefoonnummer), adres (postcode, huisnummer), gemaakte afspraken (telefonisch en op locatie) en prijs indicatie resultaten) gedeeld hebben contact met u mogen opnemen voor doeleinden met betrekking tot de verkoop en installatie van zonne-energiesystemen. Dit kan onder meer het plannen van afspraken, het verstrekken van informatie en het sluiten van overeenkomsten omvatten.
            </p>

            <h2>6. Contact</h2>

            <p>
                Als u vragen heeft over deze Algemene Voorwaarden, neem dan contact met ons op via contact@zonnemacht.nl.
            </p>


            <p>De op deze website getoonde informatie wordt door Zonnemacht met zorg samengesteld, doch voor de juistheid en volledigheid daarvan kan niet worden ingestaan.  Wijzigingen kunnen zonder voorafgaande mededeling worden aangebracht. Deze site bevat geen uitnodiging tot het kopen of verkopen van producten of diensten. De geboden informatie is niet bedoeld als een vervanging van deskundig advies. Zonder verificatie of nader advies is gebruikmaking van de geboden informatie voor eigen rekening en risico van de gebruiker.</p>
            <h2>7. Wijzigingen</h2>
            <p>De informatie kan zonder voorafgaande mededeling worden gewijzigd. </p>


        </div>
    );
}

export default Terms;


//TODO Re-format text