import React from 'react';
import './Contact.css';
import {Link} from "react-router-dom";
import NewAppointment from "../../user/profile/appointment/buttons/NewAppointment";

import CardItemContactPage from "./CardItemContactPage"; // Import your custom CSS

function Contact() {

    return (
        <div className="contact-container">

            <div className="contact-mail-and-appointment-widget-container">

                <div className="contact-mail-aligned-left make-box-around-text">
                    <h2 className="contact-title">Contact</h2>

                    <p className="contact-paragraph">Bij Zonnemacht staan we altijd klaar om je te helpen.</p>
                    <p className="contact-paragraph">Je kunt contact met ons opnemen via e-mail op het volgende
                        e-mailadres: <a className="contact-details"
                                        href="mailto:contact@zonnemacht.nl">contact@zonnemacht.nl</a>.</p>
                    <p className="contact-paragraph">Of je nu vragen hebt over onze producten, prijzen,
                        installatiediensten of iets anders, aarzel niet om contact met ons op te nemen. We waarderen je
                        interesse in zonne-energie en staan klaar om al je vragen te beantwoorden.</p>
                    <p className="contact-paragraph">Ons toegewijde team is vastbesloten om je de best mogelijke service
                        te bieden. We begrijpen dat je tijd waardevol is, daarom streven we ernaar om binnen 24 uur te
                        reageren op jouw bericht. Je kunt erop vertrouwen dat we snel en efficiënt op jouw e-mail zullen
                        reageren, zodat we je kunnen helpen bij het nemen van de volgende stappen in je reis naar
                        schone, hernieuwbare energie.</p>
                    <p className="contact-paragraph">Bedankt voor je interesse in Zonnemacht. We kijken ernaar uit om
                        van je te horen en je te ondersteunen bij jouw duurzame energiebehoeften.</p>

                </div>
                <div className="contact-mail-aligned-right">
                    <CardItemContactPage
                        src={''}
                        text='Op locatie en/of telefonisch'
                        label='Afspraak'
                        path='/schedule-appointment'/>
                </div>
            </div>

            <div className="contact-appointment-title-row">

                {/*Bottom left box*/}
                <div className="contact-appointment-title-align-start make-box-around-text">
                    <h3 className="contact-appointment-title">Telefonisch informatie & advies gesprek</h3>
                    <p className="contact-paragraph">Heb je vragen over zonne-energie of wil je meer informatie over
                        onze diensten? Geen probleem! Bij Zonnemacht staan we klaar om al je vragen te beantwoorden. Je
                        kunt eenvoudig een <Link to="/schedule-appointment">gratis telefonische afspraak</Link> met ons
                        inplannen voor een informatief gesprek.</p>
                    <p className="contact-paragraph">Tijdens dit gesprek kom je in contact met een van onze ervaren
                        experts. Deze deskundige zal je met plezier bijstaan en al je vragen over zonne-energie
                        beantwoorden. Of je nu wilt weten hoe zonne-energiesystemen werken, <Link
                            to="/schedule-appointment">geheel vrijblijvend advies</Link> nodig hebt over het installeren
                        van zonnepanelen, of <Link to="/schedule-appointment">geheel vrijblijvend
                            informatie</Link> zoekt over de voordelen van zonne-energie, wij zijn er om je te helpen.
                    </p>
                    <p className="contact-paragraph">Klaar om een afspraak te maken? Klik dan <Link
                        to="/schedule-appointment">hier</Link> om je afspraak in te plannen. We kijken ernaar uit om je
                        te helpen bij je zonne-energiebehoeften en je te voorzien van de informatie en het advies die
                        nodig is om een weloverwogen beslissing te nemen voor een duurzamere toekomst.</p>
                </div>

                {/*Bottom right box*/}
                <div className="contact-appointment-title-align-end make-box-around-text">
                    <h3 className="contact-appointment-title">Technische inspectie op locatie</h3>
                    <p className="contact-paragraph">Heb je vragen over het <Link
                        to="/schedule-appointment">installeren</Link> van zonnepanelen op jouw woning of wil je meer
                        informatie over hoe zonne-energie jouw energie-efficiëntie kan verbeteren? Geen probleem! Bij
                        Zonnemacht bieden we een scala aan diensten om aan je behoeften te voldoen. Je kunt eenvoudig
                        een telefonische afspraak met ons maken om al je vragen te laten beantwoorden door onze
                        deskundige adviseurs.</p>
                    <p className="contact-paragraph">Maar dat is nog niet alles. We begrijpen dat elk huis of bedrijf
                        uniek is, daarom bieden we ook geheel vrijblijvend technische inspecties op locatie aan. Onze
                        experts komen persoonlijk bij je langs om de situatie te beoordelen en de beste oplossingen voor
                        zonne-energie te bespreken. Of het nu gaat om het optimaliseren van dakoppervlak, het bepalen
                        van de benodigde capaciteit of het berekenen van je besparingen, wij zullen je voorzien
                        van <Link to="/schedule-appointment">geheel vrijblijvend gedetailleerd en afgestemd
                            advies</Link>.</p>
                    <p className="contact-paragraph">Ons toegewijde team van experts staat voor je klaar om je vragen te
                        beantwoorden en je te helpen bij het nemen van weloverwogen beslissingen voor je
                        zonne-energieproject.</p>
                    <p className="contact-paragraph">Klaar om een afspraak te maken? Klik dan <Link
                        to="/schedule-appointment">hier</Link> om je afspraak te plannen. We kijken ernaar uit om je te
                        helpen bij jouw zonne-energiebehoeften en om je de best mogelijke ondersteuning te bieden bij
                        elke stap van jouw zonne-energie-reis.</p>
                </div>
            </div>

            <NewAppointment/>
        </div>
    );
}

export default Contact;
