import React from 'react';
import './About.css';
import solarImage from "../../../../assets/background2.png";
import {Header, Share, Paragraph} from "../information/articles/Helper/Article";

function About() {
    return (
        <div className="about-container">

            <div className="about-box">
                <div className="about-image">
                    <img src={solarImage} alt=""/>
                </div>

                <div className="about-text">
                    <Header Header={"Over Zonnemacht"} size={'bigger'}/>
                    <Paragraph>Welkom bij Zonnemacht, gespecialiseerd in diensten met betrekking tot de energietransitie. We zijn gepassioneerd door duurzaamheid en hebben een sterke toewijding aan het bevorderen van groene en schone energieoplossingen. Onze missie is om bij te dragen aan een duurzame toekomst voor iedereen.</Paragraph>
                    <Paragraph>In een wereld die wordt geconfronteerd met klimaatverandering en de uitputting van natuurlijke hulpbronnen, spelen wij een actieve rol in het ondersteunen van bedrijven, particulieren en gemeenschappen bij hun overstap naar hernieuwbare energiebronnen. Of het nu gaat om zonne-energie, windenergie, energie-efficiëntie of andere aspecten van de energietransitie, ons team van experts staat voor u klaar.</Paragraph>
                    <Paragraph BigSpace={true}>We geloven sterk in samenwerking en partnerschappen. Samen met onze klanten, leveranciers en overheidsinstanties werken we aan innovatieve oplossingen die zowel het milieu als uw financiën ten goede komen. Ons doel is om duurzaamheid toegankelijk te maken voor iedereen, ongeacht de omvang van uw project.</Paragraph>
                    <Paragraph BigSpace={true}>Bij ons kunt u rekenen op deskundig advies, hoogwaardige producten en professionele installatiediensten. Wij staan garant voor betrouwbaarheid en transparantie in al onze activiteiten. Samen kunnen we een verschil maken en een groenere toekomst creëren voor de komende generaties.</Paragraph>
                    <Paragraph>Dank u voor het kiezen van ons als uw partner in de energietransitie. We kijken ernaar uit om samen te werken aan een duurzamere wereld.</Paragraph>

                </div>
            </div>

        </div>
    );
}

export default About;
