import React from 'react';
import HeroSection from "./HeroSection";
import "./Homepage.css";
import InstallationOffer from "./InstallationOffer";
import Request from "../../user/profile/request/Request";
import {Button} from "./Button";
import CompanyAdvertisement from "./CompanyAdvertisement";
import Zonnemacht from "../../../../assets/Zonnemacht.png";

function Homepage() {
/*    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleLinkClick = (id) => {
        scrollTo(id); // Scroll to the corresponding section
    };*/

    return (
        <div className="homepage-container">
            <HeroSection/>
{/*            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />
            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />*/}
{/*

            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />

                <CompanyAdvertisement
                    companyName="Uw bedrijfsnaam"
                    description="Adverteren op Zonnemacht.nl?"
                    website="https://zonnemacht.nl/request"
                    logoSrc={Zonnemacht}
                />

                <InstallationOffer />
            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />
            <CompanyAdvertisement
                companyName="Uw bedrijfsnaam"
                description="Adverteren op Zonnemacht.nl?"
                website="https://zonnemacht.nl/request"
                logoSrc={Zonnemacht}
            />
*/}


        </div>

    );
}

export default Homepage;

{/*
            <div className="home-below-hero-container">

                <QandA />

            </div>
*/}
